import React, { useContext } from 'react';
import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';
import { isValidRoutingNumber, getRoutingNumberErrors, routingNumberLength } from '../../../../utils/ChoosePaymentModule';
import { allowNumericOnKeypress } from '../../../../utils/KeyPress';
const routing_data_dl = {"id":"BankRoutingInput","location":"HIG Payment Page","type":"input field","da_track":"true"};
export const routing_error_data_dl = {"eventparent":"Page Tag","eventtype":"Field Level Message","eventvalue":"Routing number"};
const BankRoutingInput = ({ onTooltipClick }) => {
    const { state, setState } = useContext(ChoosePaymentContext);
    const handleOnChange = (e) => {
        const newValue = e.target.value;
        setState({ routingNumber: newValue });
        if (newValue.length < 9){
        	setState({bankName:""});
        }
    }

    const handleOnBlur = () => {
        let errors = [];
        if (!isValidRoutingNumber(state.routingNumber)) {
            errors = getRoutingNumberErrors(state.routingNumber);
        } else if (state.routingNumberErrors) {
            errors = state.routingNumberErrors;
        }
        setState({ routingNumberErrors: errors });

        if (errors.length>0){
            var routing_error_data = {
                "event_parent" : routing_error_data_dl.eventparent, 
                "event_type" : routing_error_data_dl.eventtype, 
                "event_id":  errors[0],
                "da_track": "true",
                "event_value": routing_error_data_dl.eventvalue,
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            }
            window._trackAnalytics(routing_error_data); 
        } else {
            var routing_data = { 
                "event_parent" : "Page Tag", 
                "event_type" : routing_data_dl.type, 
                "event_id":  routing_data_dl.id,
                "da_track": routing_data_dl.da_track,
                "event_value": window.location.hostname.concat(window.location.pathname),
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
                };
            window._trackAnalytics(routing_data);
        } 
    }

    return (
        <div className="banking-info__input-container">
            <label className="control-label" htmlFor="BankRoutingInput">Routing number
                {
                    !!onTooltipClick &&
                    <span className="tooltip-soft-text">
                        <i className="icon icon-info tooltip-soft-icon" onClick={onTooltipClick}></i>
                    </span>
                }

            </label>
            <input
                id="BankRoutingInput"
                tealium-change data-dl={JSON.stringify(routing_data_dl)}
                className="form-control"
                type="text"
                inputMode="numeric"
                value={state.routingNumber}
                aria-required="true"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onKeyPress={allowNumericOnKeypress.bind(null, { allow: [], maxLength: routingNumberLength })}
            />
        </div>
    )
}

export default BankRoutingInput;