import { forceShowGenericErrorPage } from './showPages'
import { postChildFrameErrorMessage } from './services'
let hasWC = false;
let hasBOP = false;

//checking if no parameters matched
export const hasNoLOBParameters = ({wcAutoPay, wcBillingType, wcBillingPlan, bopAutoPay, bopBillingType, bopBillingPlan}, setState) => {
    
    var wcParams = [wcAutoPay, wcBillingType, wcBillingPlan].filter((param)=>{return param !== undefined && param !== ''});
    var bopParams = [bopAutoPay, bopBillingType, bopBillingPlan].filter((param)=>{return param !== undefined && param !== ''});

    if(wcParams.length === 0 && bopParams.length === 0){
        forceShowGenericErrorPage('Auto%20Pay%20Is%20Missing,Installment%20Plan%20Is%20Missing,Billing%20Type%20Is%20Missing', '', '', setState);
        return true; 
    }
    return false;
}

export const isSinglePolicy = ({wcAutoPay, wcBillingType, wcBillingPlan, bopAutoPay, bopBillingType, bopBillingPlan}) => {
    var wcParams = [wcAutoPay, wcBillingType, wcBillingPlan].filter((param)=>{return param !== undefined && param !== ''});
    var bopParams = [bopAutoPay, bopBillingType, bopBillingPlan].filter((param)=>{return param !== undefined && param !== ''});

    if(wcParams.length > 0 && bopParams.length === 0){
        hasWC = true;
        return true;
    }else if(wcParams.length === 0 && bopParams.length > 0){
        hasBOP = true;
        return true;
    }else{
        return false;   
    }
}

export const isMultiPolicy = ({wcAutoPay, wcBillingType, wcBillingPlan, bopAutoPay, bopBillingType, bopBillingPlan}) => {

    var wcParams = [wcAutoPay, wcBillingType, wcBillingPlan].filter((param)=>{return param !== undefined && param !== ''});
    var bopParams = [bopAutoPay, bopBillingType, bopBillingPlan].filter((param)=>{return param !== undefined && param !== ''});

    return (wcParams.length > 0 && bopParams.length > 0)
}

export const duplicateParamsExist = ({errorMessage, wcErrorMessage, bopErrorMessage}, setState) => {
    if(errorMessage){
        postChildFrameErrorMessage('Error', errorMessage, undefined, undefined);
        forceShowGenericErrorPage(errorMessage, '', '', setState);
        return true;
    }else if(wcErrorMessage){
        postChildFrameErrorMessage('Error', undefined, wcErrorMessage, undefined);
        forceShowGenericErrorPage('', wcErrorMessage, '', setState);
        return true;
    }else if(bopErrorMessage){
        postChildFrameErrorMessage('Error', undefined, undefined, bopErrorMessage);
        forceShowGenericErrorPage('', '', bopErrorMessage, setState);
        return true;
    }
    return false;
}

export const hasAnyErrorMessage = (params, errors, state, setState) => {
    var errMsg = false
    if(duplicateParamsExist(errors, setState)){
        errMsg = true;
    }
    return (errMsg);
}

export const shouldCallServices = (props, state, setState) => {
    let params = props.params;
    let errors = {errorMessage: props.errorMessage, wcErrorMessage: props.wcErrorMessage, bopErrorMessage: props.bopErrorMessage}
    if(isMultiPolicy(params)){
        return true;
    }
    if(hasNoLOBParameters(params, setState) || (isSinglePolicy(params) && hasAnyErrorMessage(params, errors, state, setState))){
        return false;
    }
    return true; //single with no errors
}