import React, { useState, useRef, useEffect, useContext } from "react";
import { callPostCreditCardToken } from "../../../../utils/services";

import { isValidCreditCard } from "./CreditCardFunctions";
import { InitialLoadContext } from "../../../Contexts/InitialLoadContext";
import { ChoosePaymentContext } from "../../../Contexts/ChoosePaymentModuleContext";
import FormGroup from '../../../FormGroup';
const cardno_data_dl = { "id": "Card Number", "location": "HIG Payment Page", "type": "input field", "da_track": "true" };
const edit_btn_dl = { "id": "Edit", "location": "HIG Payment Page", "type": "link click", "da_track": "true", "event_value": "Card number" };

const CardNumberFormGroup = (props) => {

  const { state, setState } = useContext(ChoosePaymentContext);
  const { state: initialLoadState, setState: initialSetState } = useContext(InitialLoadContext);
  const [type, setType] = useState("unknown");
  const [valid, setValid] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const tokenIDParams = urlParams.get('tokenId');
  const iframeRef = useRef();

  const setIframeUrl = (newUrl) => {
    initialSetState({ iframeUrl: newUrl })
  }

  const setCreditCardSession = (value) => {
    initialSetState({ creditCardSession: value })
  }

  const setDisplayErrorMsg = (value) => {
    initialSetState({ dispalyErrorMsg: value })
  }

  useEffect(() => {
    if (!initialLoadState.iframeUrl) {
      initialSetState({ creditCardFieldSession: false });
      if (initialLoadState.dispalyErrorMsg) {
        console.log('initialLoadState.dispalyErrorMsg:',initialLoadState.dispalyErrorMsg);
        //condition to hide spinner
        //condition to show errorFailureIframe
        initialSetState({ crediCardDisplayErrorMsg: true });
      } else {
        //condition to show spinner
        //condition to hide errorFailureIframe
      }
    } else {
      initialSetState({ creditCardFieldSession: true });
      //condition to hide spinner
      //condition to hide errorFailureIframe
    }


  }, [initialLoadState.iframeUrl, initialLoadState.dispalyErrorMsg]);

  useEffect(() => {
    if (initialLoadState.transuccess.toLowerCase() === 'true' || !initialLoadState.transuccess) {
      setState({ cardNumberErrors: [] })
    } else {
      console.log('Please enter a valid card number');
      setState({ cardNumberErrors: ['Please enter a valid card number'] });
    }
  }, [initialLoadState.transuccess])

  const reloadIframeTokeHandler = async (e) => {
    reloadIframeAfterEdit();
    await callPostCreditCardToken(setIframeUrl, initialLoadState.OauthToken, initialSetState, setDisplayErrorMsg, setCreditCardSession, initialLoadState.partnerName, 'edit');
    initialSetState({ reloadedIframe: false });
    var edit_btn_data = {
      event_parent: "Page Tag",
      event_type: edit_btn_dl.type,
      event_id: edit_btn_dl.id,
      da_track: edit_btn_dl.da_track,
      event_value: "Card number",
      page_name: "Payment Details Page",
      external_partner_name: initialLoadState.partnerName,
      product_type: "Workers Compensation",
    };
    window._trackAnalytics(edit_btn_data);
  }

  const handleChange = (e) => {
    e.preventDefault();
  };


  //TEMPORARY
  function onIframeLoad(e) {
    if (initialLoadState.isLocalhost) {//for localhost only
      if (initialLoadState.tempusIframeHasLoaded) {
        initialSetState({ tempusRedirectLoading: true })
        window.updateCardValidationInfo({
          transuccess: 'TRUE',
          transArmorToken: '7273298826740000',
          transArmorTokenProviderId: '003'
        });
        setState({ cardNumberErrors: [] })//for sucess scenario
        // window.updateCardValidationInfo({
        //   transuccess: 'FALSE',
        //   transArmorToken: '',
        //   transArmorTokenProviderId: ''
        // });
      } else {
        initialSetState({
          tempusIframeHasLoaded: true,
          transuccess: '',
          transArmorToken: '',
          transArmorTokenProviderId: ''
        });
      }
    } else {//for all other environments
      if (initialLoadState.tempusIframeHasLoaded) {//
        initialSetState({ tempusRedirectLoading: true });

        setTimeout(() => {
          initialSetState({ tempusRedirectLoading: false })
        }, 500)

      } else {//if iframe has loaded but not yet redirected
        initialSetState({
          tempusIframeHasLoaded: true,
          transuccess: '',
          transArmorToken: '',
          transArmorTokenProviderId: ''
        });
      }

    }

  }


  const reloadIframeAfterEdit = () => {
    initialSetState({
      tempusIframeHasLoaded: false,
      transuccess: '',
      transArmorToken: '',
      transArmorTokenProviderId: ''
    });
  }
  
  useEffect(() => {

    if (initialLoadState.transArmorToken !== '') {
      var maskValue = '';
      var noMaskLength = initialLoadState.transArmorToken.length - 4;
      for (var i = 0; i < initialLoadState.transArmorToken.length; i++) {
        if (i >= noMaskLength) {
          maskValue += initialLoadState.transArmorToken[i];
        } else {
          maskValue += 'x';
        }
      }
      initialSetState({ maskedValue: maskValue });
    }
  }, [initialLoadState.transArmorToken])

  useEffect(() => {
    if (!initialLoadState.tempusRedirectLoading && initialLoadState.reloadedIframe) {
      if (initialLoadState.transuccess.toLowerCase() === 'true') {
        setState({ cardNumberErrors: [] })
      } else {
        setState({ cardNumberErrors: ['Please enter a valid card number'] })
      }
    }
  }, [initialLoadState.transuccess, initialLoadState.tempusRedirectLoading, initialLoadState.reloadedIframe])

  return (
    <>
      <FormGroup errors={state.cardNumberErrors} className='credit-card-input' >

        <label htmlFor="ccn" className="control-label">
          Card number
        </label>
        <div className="input-group">
          <span className="input-group-addon">
            <i className="icon icon-credit-card"></i>
          </span>

          {/*spinner*/
            !!initialLoadState.tempusRedirectLoading &&
            <em className="spinner-icon icon icon-spinner icon-pulse"></em>
          }

          {!!initialLoadState.transuccess && !initialLoadState.tempusRedirectLoading &&
            <div className="edit-button-display">
              <button className="btn btn-link creditCardEditButton" onClick={reloadIframeTokeHandler}
                tealium-link data-dl={JSON.stringify(edit_btn_dl)}>
                <span>Edit</span>
              </button>
              <p>
                {
                  initialLoadState.transuccess.toLowerCase() === 'true'
                    ?
                    initialLoadState.maskedValue
                    :
                    'Invalid Card Number'
                }
              </p>
            </div>
          }

          {!initialLoadState.transuccess &&
            <div className="wrapper-iframe">
              <iframe id="cardnumberiframe" title="This IFrame for card number validation." frameBorder="0" scrolling="no"
                ref={iframeRef}
                src={initialLoadState.iframeUrl} onLoad={onIframeLoad}
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
              />
            </div>
          }
        </div>
        <small className="help-block color-contract">Minimum 15 characters</small>

      </FormGroup>

    </>
  );
};

export default CardNumberFormGroup;
