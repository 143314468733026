import React from 'react';

const BankPaymentHeader = () => {
    return (
        <div className="payment-header">
            <h3 className="payment-header__title"><i className="icon icon-secure" alt="Padlock icon"></i>Secure Payment Details</h3>
        </div>
    )
}

export default BankPaymentHeader;