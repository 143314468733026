import React, { useState, useContext, useCallback, useEffect, useRef } from "react";
import IdleTimer from "react-idle-timer";

import { InitialLoadContext } from '../Contexts/InitialLoadContext';

var idleTimer;
const startOver_data_dl = { "id": "start over", "location": "HIG Timeout Page", "type": "link click", "da_track": "true" };
const SessionTimeOut = (props) => {
  const { state, setState } = useContext(InitialLoadContext);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const [idletimeout, setIdleTimeout] = useState(900000);//900000 - 15 minutes
  const [isTimedOut, setIsTimedOut] = useState(false);

  const timeoutForTokenizedCard = useRef();
  let paymentFrameInd = (state.paymentFrameInd === 'true');
  const onClickStartover = useCallback((e) => {
    e.preventDefault();
    var startOverDataDl = {
      "event_parent": "Page Tag",
      "event_type": startOver_data_dl.type,
      "event_id": startOver_data_dl.id,
      "event_value": window.location.hostname.concat(window.location.pathname),
      "da_track": startOver_data_dl.da_track,
      "page_name": "HIG Timeout Page",
      "external_partner_name": state.partnerName,
      "product_type": "Workers Compensation"
    }
    window._trackAnalytics(startOverDataDl);
    window.location.href = state.startOverURL;
  }, [state.startOverURL]);

  const logout = () => {
    setIsLoggedOut(true);
    props.setSessionInactive(true);
    if (!window.location.href.includes('errorMessage=')) {
      window.history.pushState({}, '', window.location.href + '&errorMessage=500')
    }
  };

  const handleIdle = () => {
    if (isTimedOut) {
      logout();
    } else {
      idleTimer.reset();
      setIsTimedOut(true);
      logout();
    }
  };

  const handleAction = () => {
    if (isTimedOut) {
    } else {
      idleTimer.reset();
      setIsTimedOut(true);
    }
  };

  const handleActive = () => {
    setIsTimedOut(false);
  };

  const onClickStartOverBtn = (e) => {
    e.preventDefault();
    let tid = undefined;
    if (state && state.tId) {
      tid = state.tId;
    }
    const obj = { type: "StartOver", messages: { errorMessage: undefined, wcErrorMessage: undefined, bopErrorMessage: undefined }, tId: tid };
    const myJSON = JSON.stringify(obj);
    window.parent.postMessage(myJSON, state.targetOriginURL);
  };

  useEffect(() => {
    if (isLoggedOut) {
      var sessionTimeout_data = {
        "event_name": "Page View",
        "event_parent": "Page Tag",
        "event_type": "Page View",
        "page_name": "HIG Timeout Page",
        "external_partner_name": state.partnerName,
        "product_type": "Workers Compensation"
      };
      window.utag.view(sessionTimeout_data);
    }
  }, [isLoggedOut]);

  useEffect(() => {
    if (state.transuccess.toLowerCase() === 'true' || !state.transuccess) {
      clearInterval(timeoutForTokenizedCard.current);
    } else {
      timeoutForTokenizedCard.current = setTimeout(() => {
        setState({ tokenizedCardTimeout: true })
      }, 900000)
    }
  }, [state.transuccess])

  return (
    <div>
      {(props.creditCardSessionProps || props.creditCardSessionProps == undefined) && !state.tokenizedCardTimeout ?
        <IdleTimer
          ref={(ref) => {
            idleTimer = ref;
          }}
          element={document}
          onActive={() => handleActive()}
          onIdle={() => handleIdle()}
          onAction={() => handleAction()}
          debounce={250}
          timeout={idletimeout}
        />
        :
        <IdleTimer
          ref={(ref) => {
            idleTimer = ref;
          }}
          element={document}
          onActive={() => handleActive()}
          onIdle={() => handleIdle()}
          onAction={() => handleAction()}
          debounce={250}
          timeout={900}
        />
      }
      {
        isLoggedOut && (
          <div className="todays-payment__container session-content">
            <h2 className="session-title">Your session has timed out</h2>
            <p>Looks like you may have stepped away.</p>
            <p>To help protect your information, we've timed out your session.</p>
            {
              (paymentFrameInd)
                ? <p id="paymentIframeFirstBtn"><button id="startOverFirstBtn" className='btn cancel-btn' tealium-link data-dl={JSON.stringify(startOver_data_dl)} onClick={onClickStartOverBtn}>Start Over</button></p>
                : <p> Please  <a href={state.startOverURL} tealium-link data-dl={JSON.stringify(startOver_data_dl)} onClick={onClickStartover}>try again.</a></p>
            }
          </div>
        )
      }
    </div>
  );
};

export default SessionTimeOut;
