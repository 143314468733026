import React from 'react';

import BankPaymentHeader from './BankPaymentHeader';
import BankInfoForm from './BankInfoForm';
const BankPaymentBlock = () => {
    return(
        <div>
            <BankPaymentHeader />
           	<BankInfoForm />
        </div>
    )

}

export default BankPaymentBlock;