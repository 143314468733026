const getEventKeyCode = (e) => {
    return (typeof e.which == "number") ? e.which : e.keyCode;
}

const getCharFromKeyCode = (keyCode) => {
    return String.fromCharCode(keyCode);
}

//Returns a keypress callback function
//The returned callback is intended to receive 1-2 parameters. One of the parameters will be the event passed to it when a key is pressed.
function allowableOnKeypressBuilder(starterRegexString){
    let regStr = starterRegexString;
    return function(){
        let event = null;
        let config = null;

        switch(arguments.length){
            case 1: //event gets passed only
                event = arguments[0];
                break;
            case 2: //event and configuration passed
                config = arguments[0];
                event = arguments[1];
                if(config.allow){
                    for(let i = 0; i < config.allow.length; i++){
                        regStr += config.allow[i];
                    }
                }
                break;
            default:
                break;
        }

        //check if a max length is specified 
        if(config && config.maxLength){
            if(event.target.value.length >= config.maxLength){//prevent new character and exit function
                event.preventDefault();
                return false;
            }
        }

        const code = getEventKeyCode(event);
        const char = getCharFromKeyCode(code);
    
        if( !(new RegExp('[' + regStr + ']')).test(char) ){
            console.log('failed regex check');
            event.preventDefault();
            return false;
        }else{
            console.log('passed regex check');
            return true;
        }
    }
}

//USAGE EXAMPLES:
// <Component onKeypress={ allowNumericOnKeypress } />
// <Component onKeypress={ allowNumericOnKeypress.bind(null, { allow: [' '], maxLength: 16} ) } />
// function handleOnKeypress(event){ allowNumericOnKeypress(event) }
// function handleOnKeypress(event){ allowNumericOnKeypress( {allow: [' '], maxLength: 16}, event ) } 
//DESCRIPTION: 
// Bound param object in example 2 and first param object in example 4 can contain an 'allow' property and a 'maxLength' property
//  -'allow' property is an array of additional characters allowed for the input field, such as a space
//  -'maxLength' is the maximum length the input value can be, such as 16 characters
// Function returns true if the key has been allowed and false if the key has been prevented
export const allowAlphaOnKeypress = allowableOnKeypressBuilder('a-zA-z');

export const allowNumericOnKeypress = allowableOnKeypressBuilder('0-9');

export const allowAlphaNumericOnKeypress = allowableOnKeypressBuilder('a-zA-z0-9');