import React, { useEffect, useContext } from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext';

const PaymentDetailsMessage = () => {
    const { state, setState } = useContext(InitialLoadContext);

    useEffect(() => {
        setState({ paymentDetailsMessage: 'We\'ll use this for today\'s payment' });

        if (multiPolicyMessageValidation(state) || singlePolicyMessageValidation(state)) {
            setState({ paymentDetailsMessage: 'We\'ll use this for today\'s payment and future automatic payments' });
        }
    }, [state.autoPayFromService, state.wcBillingPlan, setState]);

    function multiPolicyMessageValidation(state) {
        if (state.autoPayFromService && state.bopAutoPayFromService && state.autoPayFromService.toLowerCase() === 'yes' && state.bopAutoPayFromService.toLowerCase() === 'yes' && (state.wcBillingPlan !== '01' || state.bopBillingPlan !== '01')) {
            return true;
        }
        else {
            return false;
        }
    
    }
    
    function singlePolicyMessageValidation(state) {
    
        if ((state.autoPayFromService && state.autoPayFromService.toLowerCase() === 'yes'
            && state.wcBillingPlan !== '01' && state.bopAutoPayFromService === '') || (state.bopAutoPayFromService && state.bopAutoPayFromService.toLowerCase() === 'yes'&& state.bopBillingPlan !== '01' && state.autoPayFromService === '')) {
            return true;
        }
        else {
            return false;
        }
    
    }

    return (
        <p className="section-subtitle">{state.paymentDetailsMessage}</p>
    )
}

export default PaymentDetailsMessage;