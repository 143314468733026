import React, { useState } from 'react';

const ErrorBlock = ({ errors, formGroupId }) => {
    return (
        <div className="errors-wrapper">
            <span className="icon icon-attention form-control-feedback glyphicon-remove" aria-hidden="true"></span>
            <div className='help-block with-errors' aria-describedby={"form-group-id-" + formGroupId}>
                <ul className='list-unstyled'>
                    {errors.map((err) => {
                        return (<li key={err}>{err}</li>)
                    })}
                </ul>
            </div>
        </div>
    )
}

let formGroupId = 0;

const FormGroup = (props) => {
    const [fgID] = useState(formGroupId + 1);
    formGroupId += 1;
    const { errors = [] } = props;

    const shouldShowErrors = () => {
        return !!errors.length;
    }

    const buildClassname = () => {
        let base = shouldShowErrors() ? 'form-group has-error' : 'form-group';
        if (props.className) {
            base += ' ' + props.className;
        }
        return base;
    }

    return (
        <div className={buildClassname()} id={"form-group-id-" + fgID}>
            {props.children}
            {
                shouldShowErrors() &&
                <ErrorBlock errors={errors} formGroupId={fgID} />
            }
        </div>
    )
}

export default FormGroup;