import React, { useContext} from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext';
const cancelIssueBtn_data_dl = { "id": "CancelIssue", "location": "HIG Payment Page", "type": "button activity", "da_track": "true" };
const CancelIssueButton = () => {
    const { state } = useContext(InitialLoadContext);    

    const onClickCancelIssueBtn = (e) => {                
        e.preventDefault();          
        let tid = undefined;
        if  (state && state.tId) {	
            tid = state.tId;
        }                             
        const obj = {type: "Cancel", messages:{errorMessage: undefined, wcErrorMessage: undefined, bopErrorMessage: undefined}, tId: tid};
        const myJSON = JSON.stringify(obj);
        window.parent.postMessage(myJSON, state.targetOriginURL);                     
    };

    return (
        <button className="btn cancel-btn" tealium-link data-dl={JSON.stringify(cancelIssueBtn_data_dl)} onClick={onClickCancelIssueBtn}>
            Cancel
        </button>
    )
}

export default CancelIssueButton;