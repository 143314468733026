import React from 'react';

const ModalHeader = ({text, toggleOpen}) => {
    return (
        <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" onClick={toggleOpen}>
                <i className="icon icon-close"></i>
            </button>
            <div className="modal-title">{text}</div>
        </div>
    )
}

export default ModalHeader;