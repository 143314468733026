import React, { useContext } from 'react';

import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';
import { isValidBankingType, getBankingTypeErrors } from '../../../../utils/ChoosePaymentModule';
import FormGroup from '../../../FormGroup';
const accounttype_data_dl = {"id":"Select the account type","location":"HIG Payment Page","type":"radio button","da_track":"true"};
const AccountTypeFormGroup = () => {
    const { state, setState } = useContext(ChoosePaymentContext);

    const handleOnTypeClick = (newType) => {
        const accType = newType;
        var accounttypeDataDl = { 
            "event_parent" : "Page Tag", 
            "event_type" : accounttype_data_dl.type, 
            "event_id":  accounttype_data_dl.id,
            "event_value": accType,
            "da_track":accounttype_data_dl.da_track,
            "page_name" : "Payment Details Page",
            "external_partner_name": state.partnerName,
            "product_type":"Workers Compensation"
            }
        setState({ accountType: newType, accountTypeErrors: [] });
        window._trackAnalytics(accounttypeDataDl);
    }

    const handleOnTabOut = () => {
        const type = state.accountType
        if (!isValidBankingType(type)) {
            setState({ accountTypeErrors: getBankingTypeErrors(type) });
        }
    }
    //TODO: tab out error handling



    return (
        <FormGroup errors={state.accountTypeErrors} className="account-type-form-group toggle-container">
            <label id="account-type-title" className="toggle-title">Select the account type</label>
            <div aria-describedby="account-type-title" className="btn-group btn-group-toggle btn-group-banking-type" role="group">
                <button
                    type="button"
                    onClick={handleOnTypeClick.bind(null, 'checking')}
                    className={state.accountType === 'checking' ? "btn btn-secondary active" : "btn btn-secondary"}
                    tabIndex="0"
                    tealium-change data-dl={JSON.stringify(accounttype_data_dl)}
                >Checking</button>
                <button
                    type="button"
                    onClick={handleOnTypeClick.bind(null, 'savings')}
                    className={state.accountType === 'savings' ? "btn btn-secondary active" : "btn btn-secondary"}
                    tabIndex="0"
                    onBlur={handleOnTabOut}
                    tealium-change data-dl={JSON.stringify(accounttype_data_dl)}
                >Savings</button>
            </div>
        </FormGroup>
    )

}

export default AccountTypeFormGroup;