import React, { useState, useContext, useEffect } from 'react';

import { InitialLoadContext } from '../../Contexts/InitialLoadContext';
import { getTrueCardType } from '../../../utils/ChoosePaymentModule';

const CardTypeDisplay = () => {
    const { state } = useContext(InitialLoadContext);
    const [cardType, setCardType] = useState(getTrueCardType(state.transArmorTokenProviderId));
    useEffect(() => {
        setCardType(getTrueCardType(state.transArmorTokenProviderId))
    }, [state.transArmorTokenProviderId])
    return (
        <div className="card-type-display">
            {
                <span className={(cardType === 'DC' || cardType === "unknown") ? "svg-icon Discover-card" : "svg-icon Discover-card-grey"}></span>
            }
            {
                <span className={(cardType === 'MC' || cardType === "unknown") ? "svg-icon MC-card" : "svg-icon MC-card-grey"}></span>
            }
            {
                <span className={(cardType === 'V' || cardType === "unknown") ? "svg-icon VISA-card" : "svg-icon VISA-card-grey"}></span>
            }
            {
                <span className={(cardType === 'AM1' || cardType === "unknown") ? "svg-icon AMEX-card" : "svg-icon AMEX-card-grey"}></span>
            }
        </div>
    )
}

export default CardTypeDisplay;