import React from 'react';

import CardOwnerFormGroup from './form-groups/CardOwnerFormGroup';
import CardNumberFormGroup from './form-groups/CardNumberFormGroup';
import ExpirationDateFormGroup from './form-groups/ExpirationDateFormGroup';

const CardInfoForm = ({ updateCardType, handleOnResize }) => {

    const handleOnSubmit = (e) => {
        e.preventDefault();
    }

    const onStateChange = (stateType, value) => {
        switch (stateType) {
            case 'cardType':
                updateCardType(value);
                break;
            default:
                break;
        }
    }

    return (
        <form className="card-info-form" onSubmit={handleOnSubmit}>
            <CardOwnerFormGroup />
            <CardNumberFormGroup onCardTypeChange={onStateChange.bind(null, 'cardType')} handleOnResize={handleOnResize} />
            <ExpirationDateFormGroup />
        </form>
    );
}

export default CardInfoForm;