import React, { useContext, useEffect } from 'react';
import { InitialLoadContext } from '../Contexts/InitialLoadContext';

const InstallmentOptionsModule = () => {
    const { state } = useContext(InitialLoadContext);
    if (state.eqsIssuanceInd == 'true' && state.hasLAstateCd === 'LA') {
        return (
            <div class="installment-options">
                <div>
                    <div class="payment-icon">
                        <i className="icon icon-payments"></i>
                    </div>
                    <div class="paymentstyle">
                        Other payment options available.
                    </div>
                </div>
                <div class="callstyle">
                    Call {state.callCenterPhoneNumber}
                </div>
            </div>
        )
    }
    else {
        return <></>;
    }
}

export default InstallmentOptionsModule;