import React, { useState } from 'react';

const YearOption = ({ year }) => {
    return (
        <option value={year}>{year}</option>
    )
}

const createYearsArray = (numberOfYearsTotal) => {
    const years = [];
    let currentYear = (new Date()).getFullYear();
    for (let i = 0; i < numberOfYearsTotal; i++) {
        years.push(currentYear);
        currentYear++;
    }
    return years;
}

const YearInput = ({ handleOnYearChange, year, error }) => {

    const [disableOption, setDisableOption] = useState(false);
    const disableOptionHandler = () => {
        setDisableOption(true);
    }


    return (
        <div className="select-container expiration-year-container">
            <select name="select-year" aria-label="Select year" value={!!year ? year : 'year'} className="form-control" data-error="Please select a year." required="" onChange={(e) => handleOnYearChange(e)} onBlur={(e) => handleOnYearChange(e)} onClick={disableOptionHandler} >
                <option value="year" disabled={disableOption}>Year</option>
                {
                    createYearsArray(10).map(year => <YearOption key={year} year={year} />)
                }
            </select>
            {/* <span className="icon icon-attention form-control-feedback" aria-hidden="true"></span><div className="help-block with-errors"> */}
            {/* </div> */}
        </div>
    )
}

export default YearInput;