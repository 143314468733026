import React from 'react';

import { validateClaimCheckToken, callGetPaymentDetailsService, paramPrefixEncoding } from '../../utils/services';
import { shouldCallServices } from '../../utils/InitialLoadContextHelper';

export const InitialLoadContext = React.createContext();


//URL as a reference of parameters to expect
//https://int-payment.thehartford.com/build/index.html?tokenId=fdsfds&tokenValid=true&startOverURL=https://www.thehartford.com&errorURL=https://www.thehartford.com&successURL=https://www.thehartford.com&cancelURL=https://www.thehartford.com&billingType=Direct&installmentPlan=yearly&autoPay=yes
class InitialLoadContextWrapper extends React.Component {
    state = {
        tokenId: '',
        wcBillingPlan: '',
        bopBillingPlan: '',
        wcBillingType: '',
        bopBillingType: '',
        wcAutoPay: '',
        bopAutoPay: '',
        errorURL: '',
        cancelURL: '',
        successURL: '',
        startOverURL: '',
        OauthToken: '',
        tokenValid: false,//gets changed from the claim check token validation service
        partnerName: '',
        downPayAmt: '',
        bopDownPayAmt: '',
        lob: '',
        bopLob: '',
        isMulti: false,
        userType: '',
        bopUserType: '',
        tId: '',
        tokenResponseReceived: false,
        pageValidationResponseReceived: false,
        authenticationMessage: '',
        paymentDetailsMessage: '',
        autoPayFromService: '',
        bopAutoPayFromService: '',
        priceIsLoading: false,
        genericErrorPageErrorMessage: '',
        genericErrorPageWCErrorMessage: '',
        genericErrorPageBOPErrorMessage: '',
        forceShowErrorPage: false,
        tempusIframeHasLoaded: false,
        transuccess: '',
        transArmorToken: '',
        transArmorTokenProviderId: '',
        iframeUrl: '',
        iframeError: false,
        tempusRedirectLoading: false,
        maskedValue: '',
        reloadedIframe: false,
        isLocalhost: window.location.hostname === 'localhost',
        dispalyErrorMsg: false,
        creditCardSession: true,
        transArmorTokenError: false,
        creditCardFieldSession: false,
        crediCardDisplayErrorMsg: false,
        tokenizedCardTimeout: false,
        totalPayment: '',
        bopTypeCode: '',
        wcPaymentValidationCd: '',
        bopPaymentValidationCd: '',
        wcPaymentValidationMessage: '',
        bopPaymentValidationMessage: '',
        callCenterPhoneNumber: '',
        stateCd: '',
        eqsIssuanceInd: false,
        paymentFrameInd: false,
        targetOriginURL: '',
        hasLAstateCd: '',        
    }

    constructor(props) {
        super();
        this.setState = this.setState.bind(this);

        const allowedParams = [
            'tokenId', 'wcBillingPlan', 'wcBillingType',
            'wcAutoPay', 'bopBillingPlan', 'bopBillingType', 'bopAutoPay', 'tId'
        ];

        for (let param in props.params) {
            if (allowedParams.includes(param)) {
                this.state[param] = props.params[param];
            }
        }
        this.updateCardValidationInfo = this.updateCardValidationInfo.bind(this);
    }

    componentDidMount() {
        if (shouldCallServices(this.props, this.state, this.setState)) {
            validateClaimCheckToken({ token: this.state.tokenId }, this.state.tId, this.setState)
                .then((tokenData) => {
                    this.props.checkEQSInd(tokenData);
                    //call HIG Payment Validation Service
                    let err = {
                        'errorMessage': this.props.errorMessage, 'wcErrorMessage': this.props.wcErrorMessage, 'bopErrorMessage': this.props.bopErrorMessage, 'wcDuplicateParams':
                            this.props.wcDuplicateParams, 'bopDuplicateParams': this.props.bopDuplicateParams
                    };
                    if (this.state.OauthToken && this.state.genericErrorPageErrorMessage === '') {
                        callGetPaymentDetailsService(this.state, this.setState, err);
                    }
                }).catch((err) => {
                    if (!this.state.tokenResponseReceived) {
                        //error out during claim check token call
                        this.setState({ tokenResponseReceived: true });
                    } else {
                        //error out during HIG page validation service call
                        this.setState({ pageValidationResponseReceived: true });
                        window.location.href = decodeURIComponent(this.state.errorURL + paramPrefixEncoding(this.state.errorURL) + 'errorMessage=Internal%20Service%20Error');
                    }
                    console.log("token failed:", err);
                })
        }

        window.updateCardValidationInfo = this.updateCardValidationInfo;
    }

    updateCardValidationInfo(iframeTokenParams) {
        //TEMPORARY
        if (this.state.isLocalhost) {
            console.log('NOT from iframe redirect', iframeTokenParams)
            setTimeout(() => {
                this.setState({ tempusRedirectLoading: false })
                this.setState(iframeTokenParams);
            }, 2000);
        } else {
            this.setState({
                transArmorTokenError: false,
                tempusRedirectLoading: false,
                transuccess: iframeTokenParams['TRANSUCCESS'] ? iframeTokenParams['TRANSUCCESS'] : '',
                transArmorToken: iframeTokenParams['TRANSARMORTOKEN'] ? iframeTokenParams['TRANSARMORTOKEN'] : '',
                transArmorTokenProviderId: iframeTokenParams['TRANSARMORTOKENPROVIDERID'] ? iframeTokenParams['TRANSARMORTOKENPROVIDERID'] : ''
            });

        }

    }

    render() {
        return (
            <InitialLoadContext.Provider value={{ state: this.state, setState: this.setState }}>
                {this.props.children}
            </InitialLoadContext.Provider>
        )
    }
}

export default InitialLoadContextWrapper;