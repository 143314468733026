import React, { useState } from 'react';

const CardMonthInput = ({ handleOnDateChange, month, error }) => {

    const [disableOption, setDisableOption] = useState(false);
    const disableOptionHandler = () => {
        setDisableOption(true);
    }

    return (
        <div className="select-container expiration-month-container ">
            <select name="select-month" aria-label="Select month" value={!!month ? month : 'MM'} className="form-control" data-error="Please select a month." required="" onChange={(e) => handleOnDateChange(e)} onBlur={(e) => handleOnDateChange(e)} onClick={disableOptionHandler}  >
                <option value="MM" disabled={disableOption}>Month</option>
                <option value="01">1 - January</option>
                <option value="02">2 - February</option>
                <option value="03">3 - March</option>
                <option value="04">4 - April</option>
                <option value="05">5 - May</option>
                <option value="06">6 - June</option>
                <option value="07">7 - July</option>
                <option value="08">8 - August</option>
                <option value="09">9 - September</option>
                <option value="10">10 - October</option>
                <option value="11">11 - November</option>
                <option value="12">12 - December</option>
            </select>
        </div>
    );
}

export default CardMonthInput;