import React, { useContext, useCallback, useEffect } from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext';
import { showGenericErrorPage } from '../../utils/showPages';

const GenericErrorPage = (props) => {
    const { state } = useContext(InitialLoadContext);
    const showPage = showGenericErrorPage(state);
    let errorMessage = state.genericErrorPageErrorMessage;
    let wcErrorMessage  = state.genericErrorPageWCErrorMessage;
    let bopErrorMessage  = state.genericErrorPageBOPErrorMessage;
    let showStartOverUrlLink = false;
    let paymentFrameInd = (state.paymentFrameInd === 'true');
    const startOver_data_dl = { "id": "StartOver", "location": "HIG Payment Page", "type": "button activity", "da_track": "true" };

    if(showPage && !window.location.href.includes('&errorMessage=') && state.successURL ===true && state.cancelURL ===true && state.startOverURL ===true && state.errorURL ===true)
    {
        window.history.pushState({}, '', window.location.href + '&errorMessage=' + 'Error%20Url%20Is%20Missing%20Success%20Url%20Is%20Missing%20Start%20Over%20Url%20Is%20Missing%20Cancel%20Url%20Is%20Missing');
        
    }else if( showPage && !window.location.href.includes('&errorMessage=') && (state.successURL ==='Success%20Url%20Is%20Missing' || state.cancelURL ==='Cancel%20Url%20Is%20Missing' || state.startOverURL ==='Start%20Over%20Url%20Is%20Missing' || state.errorURL ==='Error%20Url%20Is%20Missing' ))
    {
            let PartnerUrlsArr = [state.successURL, state.cancelURL, state.startOverURL, state.errorURL];
            let PartnerUrlsFinal = PartnerUrlsArr.filter((i) => i.indexOf('Url%20Is%20Missing') > 0).join('%20');
            window.history.pushState({}, '', window.location.href + '&errorMessage=' + PartnerUrlsFinal);

    }

    if (showPage && !window.location.href.includes('&errorMessage=') && errorMessage !== '') {
        window.history.pushState({}, '', window.location.href + '&errorMessage=' + errorMessage);
    } else if (showPage && !window.location.href.includes('&wcErrorMessage=') && !window.location.href.includes('&bopErrorMessage=') && wcErrorMessage !== '' && bopErrorMessage !== '') {
        window.history.pushState({}, '', window.location.href + '&wcErrorMessage=' + wcErrorMessage + '&bopErrorMessage=' + bopErrorMessage);
    } else if (showPage && !window.location.href.includes('&wcErrorMessage=') && wcErrorMessage !== '') {
        window.history.pushState({}, '', window.location.href + '&wcErrorMessage=' + wcErrorMessage);
    } else if (showPage && !window.location.href.includes('&bopErrorMessage=') && bopErrorMessage !== '') {
        window.history.pushState({}, '', window.location.href + '&bopErrorMessage=' + bopErrorMessage);
    }

    if(state.startOverURL !== true && state.startOverURL !== 'Start%20Over%20Url%20Is%20Missing' && state.startOverURL !== ''){
        showStartOverUrlLink = true;
    }


    const onClickStartover = useCallback((e) => {
        e.preventDefault();
        window.location.href = state.startOverURL;
    }, [state.startOverURL]);

    const onClickStartOverBtn = (e) => {                
        e.preventDefault();          
        let tid = undefined;
        if ( state && state.tId ) {
            tid = state.tId;
        }                             
        const obj = {type: "StartOver", messages:{errorMessage: undefined, wcErrorMessage: undefined, bopErrorMessage: undefined}, tId: tid};
        const myJSON = JSON.stringify(obj);
        window.parent.postMessage(myJSON, state.targetOriginURL);                     
    };

    useEffect(() => {
        if (showPage) {
            var genericErrorPageDl = {
                "site_name": " HIGPayment",
                "event_name": "Page View",
                "event_parent": "Page Tag",
                "event_type": "Page View",
                "page_name": "Generic HIG Payment Error Page",
                "product_type": "Workers Compensation",
                "da_track": "true",
            }
            window._trackAnalytics(genericErrorPageDl);

            var error_data = {
                "event_parent": "Page Tag",
                "event_type": "content impression",
                "event_id": "error code",
                "da_track": "true",
                "event_value": errorMessage,
                "site_name": " HIGPayment",
                "page_name": "Generic HIG Payment Error Page",
                "product_type": "Workers Compensation"
            }
            window._trackAnalytics(error_data);

            var genericMsg_data = { 
                "event_parent" : "Page Tag", 
                "event_type" : "Warning message", 
                "event_id":  "We're Sorry, Our system isn't responding. Please wait a moment and try again.",
                "da_track":"true",
                "event_value": window.location.href,
                "page_name":"Generic HIG Payment Error Page"
            }
            window._trackAnalytics(genericMsg_data);
            props.appRef.current.classList.add('generic-error');
        } else {
            props.appRef.current.classList.remove('generic-error')
        }

    }, [showPage, props.appRef])

    return (
        <>
           
            {
                 paymentFrameInd && showPage &&
                <div className="error-page">
                    <h1 className="error-page-title">We're Sorry</h1>
                    <p>Our system isn't responding.</p>
                    <p id="paymentIframeSecondBtn"><button id="startOverSecondBtn" className='btn cancel-btn' tealium-link data-dl={JSON.stringify(startOver_data_dl)} onClick={onClickStartOverBtn}>Start Over</button>
                    </p>
                </div>
            }
            {
                showPage && !showStartOverUrlLink && !paymentFrameInd &&
                <div className="error-page">
                    <h1 className="error-page-title">We're Sorry</h1>
                    <p>Our system isn't responding.</p>
                    <p>Please wait a moment and try again.</p>
                </div>
            }
            {
                showPage && showStartOverUrlLink && state.forceShowErrorPage && !paymentFrameInd &&
                <div className="error-page">
                    <h1 className="error-page-title">We're Sorry</h1>
                    <p>Our system isn't responding.</p>
                    <p>Please <a href={state.startOverURL} className="start-over-link" onClick={onClickStartover}>try again.</a></p>
                </div>
            }
        </>
    );
}

export default GenericErrorPage;