export const HTTP_500_TOKEN_INVALID_MSG = "Invalid token";
export const HTTP_102 = 102;
export const HTTP_106 = 106;
export const HTTP_107 = 107;
export const HTTP_108 = 108;
export const HTTP_109 = 109;
export const HTTP_200 = 200;
export const HTTP_400 = 400;
export const HTTP_404 = 404;
export const HTTP_500 = 500;
export const HTTP_401 = 401;