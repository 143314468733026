import fetchTimeout from './fetchWithTimeout';
import { forceShowGenericErrorPage, showExternalPage } from '../utils/showPages';
import endpoints from './application.json';
import * as Constants from './Constants';

import {getTrueCardType} from '../utils/ChoosePaymentModule';

var domain = window.location.hostname;
var routingABNUrl = '';
var paymentDetailsUrl = '';
var savePaymentUrl = '';
var authorizeToken = '';
var xIBMClientId= '';
var xIBMClientSecret= '';
var _dl='';
var creditCardIframeTokenUrl = '';
var lobs= [];
var isNYState = false;
var successLOBs=[];
let failureLOBMap = new Map();
var hrefCAPrivacyPolicy ='';

if (domain==='dev-payment.thehartford.com' ) {
	routingABNUrl =endpoints.url.dev.validateAba;
	paymentDetailsUrl =endpoints.url.dev.paymentDetails;
	savePaymentUrl = endpoints.url.dev.saveinformation;
	authorizeToken = endpoints.url.dev.authorizeToken;
	xIBMClientId = endpoints.url.dev.xIBMClientId;
	creditCardIframeTokenUrl = endpoints.url.dev.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.dev.CPRACompliance;
} else if(domain==='int-payment.thehartford.com') {
	routingABNUrl =endpoints.url.int.validateAba;
	paymentDetailsUrl =endpoints.url.int.paymentDetails;
	savePaymentUrl = endpoints.url.int.saveinformation;
	authorizeToken = endpoints.url.int.authorizeToken;
	xIBMClientId = endpoints.url.int.xIBMClientId;
	creditCardIframeTokenUrl = endpoints.url.int.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.int.CPRACompliance;
} else if(domain==='int2-payment.thehartford.com') {
	routingABNUrl =endpoints.url.int2.validateAba;
	paymentDetailsUrl =endpoints.url.int2.paymentDetails;
	savePaymentUrl = endpoints.url.int2.saveinformation;
	authorizeToken = endpoints.url.int2.authorizeToken;
	xIBMClientId = endpoints.url.int2.xIBMClientId;
	creditCardIframeTokenUrl = endpoints.url.int2.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.int2.CPRACompliance;
} else if (domain==='tst-payment.thehartford.com') {
	routingABNUrl =endpoints.url.tst.validateAba;
	paymentDetailsUrl =endpoints.url.tst.paymentDetails;
	savePaymentUrl = endpoints.url.tst.saveinformation;
	authorizeToken = endpoints.url.tst.authorizeToken;
	xIBMClientId = endpoints.url.tst.xIBMClientId;
	xIBMClientSecret= endpoints.url.tst.xIBMClientSecret;
	creditCardIframeTokenUrl = endpoints.url.tst.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.tst.CPRACompliance;
} else if (domain==='localhost' || domain==='qa-payment.thehartford.com') {
	routingABNUrl =endpoints.url.qa.validateAba;
	paymentDetailsUrl =endpoints.url.qa.paymentDetails;
	savePaymentUrl = endpoints.url.qa.saveinformation;
	authorizeToken = endpoints.url.qa.authorizeToken;
	xIBMClientId = endpoints.url.qa.xIBMClientId;
	xIBMClientSecret= endpoints.url.qa.xIBMClientSecret;
	creditCardIframeTokenUrl = endpoints.url.qa.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.qa.CPRACompliance;
} else if(domain==='qa2-payment.thehartford.com'){
	routingABNUrl =endpoints.url.qa2.validateAba;
	paymentDetailsUrl =endpoints.url.qa2.paymentDetails;
	savePaymentUrl = endpoints.url.qa2.saveinformation;
	authorizeToken = endpoints.url.qa2.authorizeToken;
	xIBMClientId = endpoints.url.qa2.xIBMClientId;
	creditCardIframeTokenUrl = endpoints.url.qa2.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.qa2.CPRACompliance;
}else if (domain==='pte-payment.thehartford.com') {
	routingABNUrl =endpoints.url.pte.validateAba;
	paymentDetailsUrl =endpoints.url.pte.paymentDetails;
	savePaymentUrl = endpoints.url.pte.saveinformation;
	authorizeToken = endpoints.url.pte.authorizeToken;
	xIBMClientId = endpoints.url.pte.xIBMClientId;
	creditCardIframeTokenUrl = endpoints.url.pte.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.pte.CPRACompliance;
} else if (domain==='uat-payment.thehartford.com'){
	routingABNUrl =endpoints.url.uat.validateAba;
	paymentDetailsUrl =endpoints.url.uat.paymentDetails;
	savePaymentUrl = endpoints.url.uat.saveinformation;
	authorizeToken = endpoints.url.uat.authorizeToken;
	xIBMClientId = endpoints.url.uat.xIBMClientId;
	xIBMClientSecret= endpoints.url.uat.xIBMClientSecret;
	creditCardIframeTokenUrl = endpoints.url.uat.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.uat.CPRACompliance;
}
	else {
	routingABNUrl =endpoints.url.prod.validateAba;
	paymentDetailsUrl =endpoints.url.prod.paymentDetails;
	savePaymentUrl = endpoints.url.prod.saveinformation;
	authorizeToken = endpoints.url.prod.authorizeToken;
	xIBMClientId = endpoints.url.prod.xIBMClientId;
	creditCardIframeTokenUrl = endpoints.url.prod.creditCardIfrTkn;
	hrefCAPrivacyPolicy= endpoints.url.prod.CPRACompliance;
}

export function setWebAnalyticsForPartnerErrorPage(errorMessage){
	_dl = {
			"site_name": " HIGPayment",
			"page_name": "Partner Error Page",
			"product_type":"Workers Compensation"
			};
	window.utag.view(_dl);
	var error_data = {
			"event_parent" : "Page Tag", 
            "event_type" : "content impression", 
            "da_track": "true",
            "event_id": String(errorMessage),
			"event_value": window.location.href,
            "site_name": " HIGPayment",
			"page_name": "Partner Error Page",
			"product_type": String(lobs.join(" | "))
        }
    window._trackAnalytics(error_data);
}


export function setWebAnalyticsForHIGGenericErrorPage(errorMessage){
	_dl = {
			"site_name": " HIGPayment",
			"page_name": "Generic HIG Payment Error Page",
			"product_type":"Workers Compensation"
			};
	window.utag.view(_dl);
	var error_data = {
			"event_parent" : "Page Tag", 
            "event_type" : "content impression", 
            "event_id":  "error code",
            "da_track": "true",
            "event_value": String(errorMessage),
            "site_name": " HIGPayment",
			"page_name": "Generic HIG Payment Error Page",
			"product_type":"Workers Compensation"
        }
    window._trackAnalytics(error_data);	
}


function handleClaimCheckErrorResponse(statusCode, setState, tId, errorUrl) {
	if(errorUrl){
		//go to errorURL
		setWebAnalyticsForPartnerErrorPage(statusCode);
		if(tId.trim()==""){
        	tId=null;
    	}
		if (errorUrl.slice(-1) === '=') {
			errorUrl = errorUrl + tId;
		}
		else if (tId === null || tId === "") {
			errorUrl = errorUrl;
		}
		else {
			errorUrl += paramPrefixEncoding(errorUrl) + 'tId=' + tId;
		}
		showExternalPage(errorUrl + paramPrefixEncoding(errorUrl) + 'errorMessage=' + "Expired%20Claim%20Check%20Token");
	}else{
		setWebAnalyticsForHIGGenericErrorPage(statusCode);
		//for pageview tags
		forceShowGenericErrorPage(statusCode,'','', setState);
	}
}

function handleClaimCheckErrorStatuses(status, resp, tId, setState){
	const parameters = [status, setState, tId];	
	switch(status){
		case Constants.HTTP_400:
			parameters.push(resp.partnerUrls.errorUrl)
			postChildIFrameMessageWithTId('Error', 'Expired Claim Check Token', undefined, undefined, tId);
			break;
		case Constants.HTTP_404:
			parameters[0] = "Internal%20Service%20Error";
			postChildIFrameMessageWithTId('Error', 'Internal Service Error', undefined, undefined, tId);
			break;
		case Constants.HTTP_401:
			parameters[0] = "User%20Session%20Timeout";
			postChildIFrameMessageWithTId('Error', 'User Session Timeout', undefined, undefined, tId);
			break;
		case Constants.HTTP_500:
			if(resp.moreInformation && resp.moreInformation === Constants.HTTP_500_TOKEN_INVALID_MSG){
				parameters[0] = "Invalid%20Claim%20Check%20Token";
				postChildIFrameMessageWithTId('Error', 'Invalid Claim Check Token', undefined, undefined, tId);
			}
			break;
		default:
	}
	handleClaimCheckErrorResponse(...parameters);
}


function handleErrorMessage(index, setState){
	switch(index){
		case 'successUrl':
			setState({
				successURL: 'Success%20Url%20Is%20Missing'
			})
			break;
		case 'cancelUrl':
			setState({
				cancelURL: 'Cancel%20Url%20Is%20Missing'
			})
			break;
		case 'startOverUrl':
			setState({
				startOverURL: 'Start%20Over%20Url%20Is%20Missing'
			})
			break;
		case 'errorUrl':
			setState({
				errorURL: 'Error%20Url%20Is%20Missing'
			})
			break;
		default:
	}
}

function setPartnerUrls(resp, tId, setState){

    let {successUrl, cancelUrl, startOverUrl, errorUrl, targetOriginUrl} = resp.partnerUrls;	
	let PartnerUrlArr = [];
    if(tId.trim()==""){
        tId=null;
    }

	//if all good>
	if(successUrl && cancelUrl && startOverUrl && errorUrl){
		//if url ends with "=", just append tId value, else append fully "&tid=123"
		if ((successUrl.slice(-1) === '=') || (cancelUrl.slice(-1) === '=') 
		|| (startOverUrl.slice(-1) === '=')  || (errorUrl.slice(-1) === '=') ) {
			successUrl = successUrl + tId;
			cancelUrl = cancelUrl + tId;
			startOverUrl = startOverUrl + tId;
			errorUrl = errorUrl + tId;
		} 
		else if (tId === null || tId === "") {
				successUrl = successUrl;
				cancelUrl = cancelUrl;
				startOverUrl = startOverUrl;
				errorUrl = errorUrl;
			} 
		else {
			successUrl += paramPrefixEncoding(successUrl) + 'tId=' + tId;
			cancelUrl += paramPrefixEncoding(cancelUrl) + 'tId=' + tId;
			startOverUrl += paramPrefixEncoding(startOverUrl) + 'tId=' + tId;
			errorUrl += paramPrefixEncoding(errorUrl) + 'tId=' + tId;
		}
        setState({
                tokenValid: true, OauthToken: resp.access_token, tokenResponseReceived: true,
                successURL: successUrl,
                cancelURL: cancelUrl,
                startOverURL: startOverUrl,
                errorURL: errorUrl,
                forceShowErrorPage: false
        });
	} else{  //else>bad
		//if all 4 missing, 200 generic
		if(!successUrl && !cancelUrl && !startOverUrl && !errorUrl){
			setWebAnalyticsForHIGGenericErrorPage(Constants.HTTP_400);
			forceShowGenericErrorMessage(setState);
		} else if(!successUrl || !cancelUrl || !startOverUrl || !errorUrl){
			setWebAnalyticsForPartnerErrorPage(Constants.HTTP_400);
			if(!successUrl){
				PartnerUrlArr.push("successUrl");
			}
			if(!cancelUrl)	{
				PartnerUrlArr.push("cancelUrl");
			}
			if(!startOverUrl)	{
				PartnerUrlArr.push("startOverUrl");
			} else {
                setState({
                    startOverURL: startOverUrl
                })
            }
			if(!errorUrl){
				PartnerUrlArr.push("errorUrl");
			}
			
			if(Array.isArray(PartnerUrlArr) && PartnerUrlArr.length > 0) {
				for(let i = 0; i < PartnerUrlArr.length > 0; i++){
					handleErrorMessage(PartnerUrlArr[i], setState);
				}
			}
			setState({
				forceShowErrorPage: true
			})
		}
	}
	//targetOriginUrl - required for Iframing
	if (targetOriginUrl) {
		setState({
			targetOriginURL: targetOriginUrl			
		})
	}
}


function forceShowGenericErrorMessage(setState){
	setState({
		successURL: true,
		cancelURL: true,
		startOverURL: true,
		errorURL: true,
		forceShowErrorPage: true
	})
  }

export function validateClaimCheckToken(reqData, transactionId, setState) {
	var sendDate = (new Date()).getTime();
	return fetchTimeout(authorizeToken, {
		method: "post",
		headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'x-ibm-client-id':xIBMClientId,
        },
		body: JSON.stringify(reqData),
	  }, 60000)
	  .then((resp) => {
		return Promise.all([resp.status, resp.json()]);
	})
	.then((data) => {
		var receiveDate = (new Date()).getTime();
		var responseTimeMs = receiveDate - sendDate;
		trackResponseTime(responseTimeMs, 'Authorize service response time');
		const status = data[0];
		const resp = data[1];

		if(status !== Constants.HTTP_200){
			handleClaimCheckErrorStatuses(status, resp,transactionId, setState);
		}else{
			setPartnerUrls(resp, transactionId, setState);
			setState({callCenterPhoneNumber: resp.callCenterPhoneNumber});
			setState({eqsIssuanceInd: resp.eqsIssuanceInd});
			setState({paymentFrameInd: resp.paymentFrameInd});
		}
		return data;
	}).catch(error => {	
		var receiveDate = (new Date()).getTime();
		var responseTimeMs = receiveDate - sendDate;
		trackResponseTime(responseTimeMs, 'Authorize service response time');
		// this will be called when timeout happens	
		postChildIFrameMessageWithTId("Error",  'Internal Service Error', undefined, undefined, transactionId);						
		setWebAnalyticsForPartnerErrorPage('500');		
	});

}

//call validate routing service
export function callGetPaymentDetailsService(state, setState, err) {
	var sendDate = (new Date()).getTime();
	setState({priceIsLoading:true});
	var paymentOptions = [];

    if(state.wcBillingPlan || state.wcBillingType  || state.wcAutoPay) {
		if(err.wcErrorMessage === ''){
			var wcPayload;
			if (state.wcBillingType === 'Payroll%20Billing') {
				wcPayload = {
					billingType: state.wcBillingType,
					lob: "WC",
                    duplicateParam: err.wcDuplicateParams
				};
				if(state.wcAutoPay){
					wcPayload = {...wcPayload, autoPayInd: state.wcAutoPay};
				}
				if(state.wcBillingPlan){
					wcPayload = {...wcPayload, paymentPlanCd: state.wcBillingPlan};
				}
				paymentOptions.push(wcPayload);
				lobs.push('WC');
			} else {
				wcPayload = {
					billingType: state.wcBillingType,
					paymentPlanCd: state.wcBillingPlan,
					autoPayInd: state.wcAutoPay,
					lob: "WC",
                    duplicateParam: err.wcDuplicateParams
				};
				paymentOptions.push(wcPayload);
				lobs.push('WC');
			}
        }else{
			setState({priceIsLoading:false, lob: 'WC'});
			postChildIframeMessage('Error', undefined, err.wcErrorMessage, undefined, state);			
			if (!state.paymentFrameInd) {
				window.history.replaceState(null, null, window.location.href + '&wcErrorMessage=' + err.wcErrorMessage);
			}
		}
    }

	if(state.bopBillingType || state.bopBillingPlan  || state.bopAutoPay )
	{
		if(err.bopErrorMessage === ''){
			var bopPayload = {
				billingType: state.bopBillingType,
				paymentPlanCd: state.bopBillingPlan,
				autoPayInd: state.bopAutoPay,
				lob: "BOP",
                duplicateParam: err.bopDuplicateParams
			};
			paymentOptions.push(bopPayload);
        	lobs.push('BOP')
		}else{
			setState({priceIsLoading:false, bopLob: 'BOP', bopTypeCode: "PropLiab"});
			postChildIframeMessage('Error', undefined, undefined, err.bopErrorMessage, state);			
			if (!state.paymentFrameInd) {
				window.history.replaceState(null, null, window.location.href + '&bopErrorMessage=' + err.bopErrorMessage);
			}
		}
	}

		var payload = {
			"paymentOptions":  paymentOptions 
		}

	fetchTimeout(paymentDetailsUrl, {
		method: "POST",
		headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
			'x-ibm-client-id':xIBMClientId,
			'authorization': 'Bearer ' + state.OauthToken
        },     
		body: JSON.stringify(payload),
	}, 60000)	
	.then(response => {
			return Promise.all([response.status, response.json()]);
	}).then((dataArray) => {
		var receiveDate = (new Date()).getTime();
		var responseTimeMs = receiveDate - sendDate;
		trackResponseTime(responseTimeMs, 'Payment details service response time');
		const status = dataArray[0];
		const data = dataArray[1];

	// Checking data had valid array response
	if (data && data.paymentDetails && Array.isArray(data.paymentDetails)) {
		callingPolicyValidation(status, data.paymentDetails, data.totalPayment,data.partnerName, state, setState);
	}

	if(lobs.length === 1 && data.paymentDetails[0].stateCd === 'NY') {
        setState({stateCd:'NY'});
    } else if(lobs.length === 2 && (data.paymentDetails[0].stateCd === 'NY' || data.paymentDetails[1].stateCd === 'NY')){
        setState({stateCd:'NY'});
    }

	if(lobs.length === 1 && data.paymentDetails[0].stateCd === 'LA') {
        setState({hasLAstateCd:'LA'});
    } else if(lobs.length === 2 && (data.paymentDetails[0].stateCd === 'LA' || data.paymentDetails[1].stateCd === 'LA')){
        setState({hasLAstateCd:'LA'});
    }

	//Backend Down, no data.paymentDetails were provided.
	if(status === 500 && data.error && data.error.type === 'Exception'){
		//call web analytics for p
	    if(lobs.length === 1) {
			setWebAnalyticsForPartnerErrorPage('Internal Service Error');
			postChildIframeMessage("Error", 'Internal Service Error', undefined, undefined, state);			
			if (!state.paymentFrameInd) {
				window.location.href = decodeURIComponent(state.errorUrl + paramPrefixEncoding(state.errorURL) + lobs[0].toLowerCase() + 'ErrorMessage=' + 'Internal Service Error');
			}
        } else {
			setWebAnalyticsForPartnerErrorPage('Internal Service Error');
			postChildIframeMessage("Error", undefined, 'Internal Service Error', 'Internal Service Error', state);				
			if (!state.paymentFrameInd) {
				window.location.href = decodeURIComponent(state.errorURL + paramPrefixEncoding(state.errorURL) + 'wcErrorMessage=' + 'Internal Service Error' + '&' + 'bopErrorMessage=' + 'Internal Service Error');
			}
        }
    } else if(status === 500){		
		if(lobs.length === 1) {
			postChildIframeMessage("Error", 'Internal Service Error', undefined, undefined, state);			
			setWebAnalyticsForPartnerErrorPage('Internal Service Error');			
        } else {
			postChildIframeMessage("Error", undefined, 'Internal Service Error', 'Internal Service Error', state);							
			setWebAnalyticsForPartnerErrorPage('Internal Service Error');
        }
	}

	}).catch(error => {	
		var receiveDate = (new Date()).getTime();
		var responseTimeMs = receiveDate - sendDate;
		trackResponseTime(responseTimeMs, 'Payment details service response time');
		// this will be called when timeout happens	
		postChildIframeMessage("Error",  'Internal Service Error', undefined, undefined, state);						
		setWebAnalyticsForPartnerErrorPage('500');
		if (!state.paymentFrameInd) {
			window.location.href = decodeURIComponent(state.errorURL + paramPrefixEncoding(state.errorURL) + 'errorMessage=' + 'Internal Service Error');
		}
	});
}
export function callingPolicyValidation(status, paymentDetails, totalPayment,partnerName, state, setState) {
		if (status === Constants.HTTP_200) {
			handleGetPaymentStatus200(paymentDetails, totalPayment, partnerName, state, setState);
		}
		else if (status === Constants.HTTP_400) {
			handleGetPaymentStatus400(paymentDetails, state);
		}
		else {
			postChildIframeMessage("Error", status, undefined,  undefined, state);		
			// currently should be only 500
			setWebAnalyticsForPartnerErrorPage(status);
			if (!state.paymentFrameInd) {
				window.location.href = decodeURIComponent(state.errorURL + paramPrefixEncoding(state.errorURL) + 'errorMessage=' + status);
			}
		}
	setState({pageValidationResponseReceived: true});
	_dl = {
		"site_name": "HIGPayment",
		"page_name": "Payment Details Page",
		"external_partner_name": partnerName,
		"product_type":"Workers Compensation"
		};
 	window.utag.view(_dl); 
}

function handleGetPaymentStatus200(paymentDetails, totalPayment, partnerName, state, setState) {
	for (let i = 0; i <= paymentDetails.length - 1; i++) {
		if (paymentDetails[i].paymentValidationMessage !== 'Success') {
			//need analytics call			
			setWebAnalyticsForPartnerErrorPage(paymentDetails[i].paymentValidationMessage);
			if ('wc' === paymentDetails[i].lob.toLowerCase()) {
				failureLOBMap.set('wc', paymentDetails[i].paymentValidationMessage);				
			} else if ('bop' === paymentDetails[i].lob.toLowerCase()) {
				failureLOBMap.set('bop', paymentDetails[i].paymentValidationMessage);				
			}
			window.history.replaceState(null, null, window.location.href + '&' + paymentDetails[i].lob.toLowerCase() + 'ErrorMessage=' + paymentDetails[i].paymentValidationMessage);
			if(onlySuccessIsPayrollBilling(paymentDetails)){
				window.location.href = state.errorURL + '&' + paymentDetails[i].lob.toLowerCase() + 'ErrorMessage=' + paymentDetails[i].paymentValidationMessage;
			}
		}
		callingHandlePaymentDetails(paymentDetails[i], totalPayment, partnerName, setState);
	}
}

function onlySuccessIsPayrollBilling(paymentDetails){
	let isOnlySuccessPayrollBilling = true;
	for(const paymentDetail of paymentDetails){
		if(paymentDetail.paymentValidationMessage.toLowerCase() === "Success".toLowerCase()
		&& !(paymentDetail.lob === "WC" && paymentDetail.billingType === "Payroll Billing")){
			isOnlySuccessPayrollBilling = false;
		}
	}
	return isOnlySuccessPayrollBilling;
}

function handleGetPaymentStatus400(paymentDetails, state) {
	let wcErrorMessage = '';
	let bopErrorMessage = '';	
	if ("MoreThanTwoPolicyNumbersArePassed" !== paymentDetails[0].paymentValidationMessage && "MoreThanOnePaymentOptionsForSameLobsArePassed" !== paymentDetails[0].paymentValidationMessage && "MoreThanOnePaymentOptionsForSameLobsArePassed" !== paymentDetails[0].paymentValidationMessage) {
		let urlBuilder = state.errorURL;
		for (let i = 0; i <= paymentDetails.length - 1; i++) {
			urlBuilder += paramPrefixEncoding(urlBuilder);
			urlBuilder += paymentDetails[i].lob.toLowerCase() + 'ErrorMessage=' + paymentDetails[i].paymentValidationMessage;
			if(paymentDetails[i].lob.toLowerCase() === 'wc') {
				wcErrorMessage = paymentDetails[i].paymentValidationMessage;				
			} else if(paymentDetails[i].lob.toLowerCase() === 'bop') {
				bopErrorMessage = paymentDetails[i].paymentValidationMessage;				
			}
		}		
		postChildIframeMessage("Error", undefined, wcErrorMessage,  bopErrorMessage, state);		
		setWebAnalyticsForPartnerErrorPage(urlBuilder);
		if (!state.paymentFrameInd) {
			window.location.href = decodeURIComponent(urlBuilder);
		}
	}
	else {
		// currently expected Reason Codes 15,16,17
		postChildIframeMessage("Error",  paymentDetails[0].paymentValidationMessage, undefined, undefined, state);						
		setWebAnalyticsForPartnerErrorPage(paymentDetails[0].paymentValidationMessage);
		if (!state.paymentFrameInd) {
			window.location.href = decodeURIComponent(state.errorURL + paramPrefixEncoding(state.errorURL) + 'errorMessage=' + paymentDetails[0].paymentValidationMessage);
		}
	}
}

export function handlePaymentDetailsWCResponse(data, totalPayment, partnerName, setState){

	if(data.paymentValidationMessage === "Success" && data.billingType === "Direct Bill"){
	    successLOBs.push('WC');
		setState({priceIsLoading:false, wcBillingPlan: data.paymentPlanCd, 
			partnerName: partnerName, downPayAmt: data.initialDownPayment, lob: data.lob, wcBillingType:data.billingType, 
			userType: data.customerCode, autoPayFromService: data.autoPayInd, totalPayment:totalPayment, wcPaymentValidationMessage: data.paymentValidationMessage });
	} else {
		setState({priceIsLoading:false, lob: data.lob, wcPaymentValidationMessage: data.paymentValidationMessage});
	}		
  }

export function handlePaymentDetailsBOPResponse(data, totalPayment, partnerName, setState){
	
	if(data.paymentValidationMessage === "Success"){
	    successLOBs.push('BOP');
		setState({priceIsLoading:false, bopBillingPlan: data.paymentPlanCd, 
			partnerName: partnerName, bopDownPayAmt: data.initialDownPayment, bopLob: data.lob, bopBillingType:data.billingType, 
			bopUserType: data.customerCode, bopAutoPayFromService: data.autoPayInd, totalPayment:totalPayment,  bopTypeCode: data.bopTypeCode, bopPaymentValidationMessage: data.paymentValidationMessage });
	} else {
		setState({priceIsLoading:false, bopLob: data?.lob, bopTypeCode: data.bopTypeCode, bopPaymentValidationMessage: data.paymentValidationMessage });
	} 
  }

  export function callingHandlePaymentDetails(paymentDetails ,totalPayment, partnerName, setState){
		if(paymentDetails.lob === 'WC')
		{
		handlePaymentDetailsWCResponse(paymentDetails, totalPayment, partnerName, setState);
		} else
		{
		handlePaymentDetailsBOPResponse(paymentDetails, totalPayment, partnerName, setState);
		}
  }



// call validate routing service
export function callBankABAValidationService(state, setState, OauthToken) {
	setState({ bankNameIsLoading: true });
	var payload = {
			routingNumber: state.routingNumber
	};
	fetchTimeout(routingABNUrl, {
		method: "POST",
		headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'x-ibm-client-id':xIBMClientId,
            'authorization': 'Bearer ' + OauthToken
        },
		body: JSON.stringify(payload),
	}, 60000)	
	.then((response) => {
		  return handleBankABAErrors(response, setState, state)})
	.then(response => response.json())
	.then(data=> {
		handleBankABAServiceResponse(data, setState, state);
	}).catch(error => {		
		setState({
            bankName: "",
            bankNameIsLoading: false,
            routingNumberErrors: [],
          });
	});
}

//handle errors from JSON file or from API
export function handleBankABAErrors(response, setState, state) {
	// 500 errors/ timeout
    if (!response.ok) {
    	response.json().then((json) => {
    		if (json.error.paymentValidationCd === '09'){
    			setState({
    	            bankName: "",
    	            bankNameIsLoading: false,
    	            routingNumberErrors: ['We cannot identify a bank associated with the routing number you entered. Please check the number and try again'],
    	          });
    		} else {
    			setState({
    		        bankName: "",
    		        bankNameIsLoading: false,
    		        routingNumberErrors: [],
    		      });
    		}
    	});
    }
    return response;
}

export function handleBankABAServiceResponse(data, setState, state){
	// success scenario
	if (!!data.bankName) {		
        setState({
          bankName: data.bankName,
          bankNameIsLoading: false,
          routingNumberErrors: [],
        });       
	} else {
		 //error scenario
		setState({
	        bankName: "",
	        bankNameIsLoading: false,
	        routingNumberErrors: [],
	      });
	}
}

export function callSavePaymentInformation(state, initialState) {

	var savePaymentInfoRequest = {};
	if(state.paymentType === "EFT"){
		var accountTypeCd = "";
		if (state.accountType==='checking'){
			accountTypeCd = "02";
		} else if (state.accountType==='savings'){
			accountTypeCd = "01";
		}
		savePaymentInfoRequest ={
            accountName: state.nameOnAccount,
			acctTypeCd: accountTypeCd,
			bankId: state.routingNumber,
			accountNumberId: state.accountNumber,
			methodPaymentCd: state.paymentType,
            lobs: successLOBs
		  }
	}else if(state.paymentType === "005"){
		savePaymentInfoRequest = {
            accountName: state.nameOnCard,
			accountNumberId: initialState.transArmorToken,
			acctTypeCd: getTrueCardType(initialState.transArmorTokenProviderId),
			methodPaymentCd: state.paymentType,
			creditCardExpirationDt: state.expirationYear + '-' + state.expirationMonth,
            lobs: successLOBs
		  }
	}
	var sendDate = (new Date()).getTime();
	  fetchTimeout(savePaymentUrl, {
	  method: "POST",
	  headers: {
	          'Accept': 'application/json',
	          'Content-Type': 'application/json;charset=UTF-8',
	          'x-ibm-client-id':xIBMClientId,
	          'authorization': 'Bearer ' + initialState.OauthToken
	      },
	  body: JSON.stringify(savePaymentInfoRequest),
	}, 60000)
          .then(response => {
              return Promise.all([response.status, response.json()]);
          }).then((dataArray) => {
          var receiveDate = (new Date()).getTime();
          var responseTimeMs = receiveDate - sendDate;
          trackResponseTime(responseTimeMs, 'Payment details service response time');
          const status = dataArray[0];
          const data = dataArray[1];
		return handleFormDetailsErrors(status, data, state, initialState.successURL, initialState.errorURL, initialState)
	}).catch(error => {	
		var receiveDate = (new Date()).getTime();
		var responseTimeMs = receiveDate - sendDate;
		trackResponseTime(responseTimeMs, 'SavePayment service response time');
		// this will be called when timeout happens	
		postChildIframeMessage("Error",  'Internal Service Error', undefined, undefined, initialState);						
		setWebAnalyticsForPartnerErrorPage('500');		
	});
		
}

export function handleFormDetailsErrors(status, response, state, successURL, errorURL, initialState) {
	let isMessagePosted = false;
	if (status === 500 || status === 400) {
		setWebAnalyticsForPartnerErrorPage(status);
		if (lobs.length === 1) {
			handleSinglePolicyErrorScenario(status, response, errorURL, state, initialState)
			isMessagePosted = true;
		} else {
			handleMultiPolicyErrorScenario(status, response, errorURL, state, initialState)
			isMessagePosted = true;
		}
	} else {
		//partnername, eventid, eventvalue, pagename
		successEvents(state.partnerName, response.status, successURL, "Partner Success Page");
		savePaymentSuccessResponseHandler(status, response, successURL, errorURL, initialState)
	}
	if (status === 500 && response.error && response.error.type === 'Exception') {
		setWebAnalyticsForPartnerErrorPage("Internal Service Error");
		const obj = '';
		if (lobs.length === 1) {
			if (lobs.includes('WC') && !isMessagePosted) {
				postChildIframeMessage('Error', undefined, 'Internal Service Error', undefined, initialState);
			} else if (lobs.includes('WC') && !isMessagePosted) {
				postChildIframeMessage('Error', undefined, undefined, 'Internal Service Error', initialState);
			}
			if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(state.errorURL + paramPrefixEncoding(state.errorURL) + lobs[0].toLowerCase() + 'ErrorMessage=' + 'Internal Service Error')
			}

		} else {
			if (!isMessagePosted) {
				postChildIframeMessage('Error', undefined, 'Internal Service Error', 'Internal Service Error', initialState);
			}
			if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(state.errorURL + paramPrefixEncoding(state.errorURL) + 'wcErrorMessage=' + 'Internal Service Error' + '&' + 'bopErrorMessage=' + 'Internal Service Error')
			}
		}


	}
	return response;
}

export function handleSinglePolicyErrorScenario(status, response, errorURL, initialState) {
    if (lobs.includes('WC')) {
        updateSinglePolicyErrorURL ('wc',status,response, errorURL, initialState)
    } else {
        updateSinglePolicyErrorURL ('bop',status, response, errorURL, initialState)
    }
}

export function updateSinglePolicyErrorURL (lob,status,response, errorURL, initialState){    
	if(status === 400) {		
		if (lobs.includes('WC')) {
			postChildIframeMessage('Error', undefined, response.paymentInfoResp[0].paymentValidationMessage, undefined, initialState); 
		} else {
			postChildIframeMessage('Error', undefined, undefined, response.paymentInfoResp[0].paymentValidationMessage, initialState); 
		}
		if (!initialState.paymentFrameInd) {
			window.location.href = decodeURIComponent(errorURL + paramPrefixEncoding(errorURL) +lob+'ErrorMessage='+response.paymentInfoResp[0].paymentValidationMessage);
		}
    } else {		
		if (lobs.includes('WC')) {
			postChildIframeMessage('Error', undefined, 'Internal Service Error', undefined, initialState); 
		} else {
			postChildIframeMessage('Error', undefined, undefined, 'Internal Service Error', initialState); 
		}
		if (!initialState.paymentFrameInd) {
			window.location.href = decodeURIComponent(errorURL + paramPrefixEncoding(errorURL) +lob+'ErrorMessage='+'Internal Service Error');		
		}
    }	
}

export function postChildIframeMessageLOB(eventType, lob, errorMsg, wcErrorMsg, bopErrorMsg, state ) {
	let guid = undefined;
	if  (state && state.tId) {	
		guid = state.tId;		
	} 
	
	let targetOrigin = '*';
	if  (state && state.targetOriginURL) {	
		targetOrigin = state.targetOriginURL;		
	}
	if (window.location !== window.parent.location) {
		const obj = {type: eventType, lob: lob, messages:{errorMessage: errorMsg, wcErrorMessage: wcErrorMsg, bopErrorMessage: bopErrorMsg}, tId: guid};
		const myJSON = JSON.stringify(obj);		
		window.parent.postMessage(myJSON, targetOrigin); 		
	}
}

export function postChildIframeMessage(eventType, errorMsg, wcErrorMsg, bopErrorMsg, state ) {
	let guid = undefined;
	if  (state && state.tId) {	
		guid = state.tId;		
	} 
	
	let targetOrigin = '*';
	if  (state && state.targetOriginURL) {	
		targetOrigin = state.targetOriginURL;		
	}
	if (window.location !== window.parent.location) {
		const obj = {type: eventType, messages:{errorMessage: errorMsg, wcErrorMessage: wcErrorMsg, bopErrorMessage: bopErrorMsg}, tId: guid};
		const myJSON = JSON.stringify(obj);		
		window.parent.postMessage(myJSON, targetOrigin); 		
	}
}

export function postChildFrameErrorMessage(eventType, errorMsg, wcErrorMsg, bopErrorMsg) {
	if (window.location !== window.parent.location) {
		const obj = { type: eventType, messages: { errorMessage: errorMsg, wcErrorMessage: wcErrorMsg, bopErrorMessage: bopErrorMsg } };
		const myJSON = JSON.stringify(obj);		
		window.parent.postMessage(myJSON, '*');		
	}
}

export function postChildIFrameMessageWithTId(eventType, errorMsg, wcErrorMsg, bopErrorMsg, tid) {
	let guid = undefined;
	if  (tid) {	
		guid = tid;		
	} 
	if (window.location !== window.parent.location) {
		const obj = { type: eventType, messages: { errorMessage: errorMsg, wcErrorMessage: wcErrorMsg, bopErrorMessage: bopErrorMsg }, tId: guid};
		const myJSON = JSON.stringify(obj);		
		window.parent.postMessage(myJSON, '*');		
	}
}

export function handleMultiPolicyErrorScenario(status, response, errorURL, initialState){
    if(status=== 400) {
        if (response.paymentInfoResp[0].lob === 'WC') {			
			postChildIframeMessage('Error', undefined, response.paymentInfoResp[0].paymentValidationMessage, response.paymentInfoResp[1].paymentValidationMessage, initialState); 			
			if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(errorURL + paramPrefixEncoding(errorURL) +'wcErrorMessage='+response.paymentInfoResp[0].paymentValidationMessage + '&bopErrorMessage=' + response.paymentInfoResp[1].paymentValidationMessage);
			}
        } else {
			postChildIframeMessage('Error', undefined, response.paymentInfoResp[1].paymentValidationMessage, response.paymentInfoResp[0].paymentValidationMessage, initialState); 
            if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(errorURL + paramPrefixEncoding(errorURL) + 'wcErrorMessage='+response.paymentInfoResp[1].paymentValidationMessage + '&bopErrorMessage=' + response.paymentInfoResp[0].paymentValidationMessage);
			}
        }
    } else {
		postChildIframeMessage('Error', undefined, 'Internal Service Error', 'Internal Service Error', initialState); 
        if (!initialState.paymentFrameInd) {
			window.location.href = decodeURIComponent(errorURL + paramPrefixEncoding(errorURL) + 'wcErrorMessage='+'Internal Service Error' + '&bopErrorMessage=' + 'Internal Service Error');
		}
    }
}

export function savePaymentSuccessResponseHandler(status, response, successURL, errorURL,  initialState) {   
	if (status === 200 && response.paymentInfoResp.length === 1 && response.paymentInfoResp[0].paymentValidationMessage === 'Success') {						
		//If there are failure loading single policy, then send the error message back to IFrame Application along with sucess message
		if (failureLOBMap.size >= 1) {
			if (failureLOBMap.has('wc') == true) {				
				postChildIframeMessageLOB('Success', successLOBs, undefined, failureLOBMap.get('wc'), undefined, initialState); 			
			} else if (failureLOBMap.has('bop') == true) {				
				postChildIframeMessageLOB('Success', successLOBs, undefined, undefined, failureLOBMap.get('bop'), initialState); 			
			}
		} else {
			postChildIframeMessageLOB('Success', successLOBs, undefined, undefined, undefined, initialState);
		}	
		if (!initialState.paymentFrameInd) {
			window.location.href = decodeURIComponent(successURL);
		}
    }
    if (status === 200 && response.paymentInfoResp.length === 2) {
        if (response.paymentInfoResp[0].paymentValidationMessage === 'Success' && response.paymentInfoResp[1].paymentValidationMessage === 'Success') {			
			postChildIframeMessageLOB('Success', successLOBs, undefined, undefined, undefined, initialState); 			
            if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(successURL);
			}
        } else {
            handleSinglePolicySuccessScenario(response,successURL, initialState)
        }
    }
}

export function handleSinglePolicySuccessScenario(response,successURL, initialState) {	
    if (response.paymentInfoResp[0].paymentValidationMessage !== 'Success' && response.paymentInfoResp[1].paymentValidationMessage === 'Success') {
        if (response.paymentInfoResp[0].lob === 'WC') {			
			postChildIframeMessageLOB('Success', successLOBs, undefined, response.paymentInfoResp[0].paymentValidationMessage, undefined, initialState); 			
            if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(successURL + paramPrefixEncoding(successURL) + 'wcErrorMessage=' + response.paymentInfoResp[0].paymentValidationMessage);
			}
        } else {			
			postChildIframeMessageLOB('Success', successLOBs, undefined, undefined,  response.paymentInfoResp[0].paymentValidationMessage, initialState); 			
            if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(successURL + paramPrefixEncoding(successURL) + 'bopErrorMessage=' + response.paymentInfoResp[0].paymentValidationMessage);
			}
        }
    }
    if (response.paymentInfoResp[0].paymentValidationMessage === 'Success' && response.paymentInfoResp[1].paymentValidationMessage !== 'Success') {		
		if (response.paymentInfoResp[1].lob === 'WC') {			
			postChildIframeMessageLOB('Success', successLOBs, undefined, response.paymentInfoResp[1].paymentValidationMessage, undefined, initialState); 			
            if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(successURL + paramPrefixEncoding(successURL) + 'wcErrorMessage=' +response.paymentInfoResp[1].paymentValidationMessage);
			}
        } else {			
			postChildIframeMessageLOB('Success', successLOBs, undefined, undefined,  response.paymentInfoResp[1].paymentValidationMessage, initialState); 			
            if (!initialState.paymentFrameInd) {
				window.location.href = decodeURIComponent(successURL + paramPrefixEncoding(successURL) + 'bopErrorMessage=' +response.paymentInfoResp[1].paymentValidationMessage);
			}
        }
    }
}



export async function callPostCreditCardToken (setiframeUrl, oauthToken, initialSetState, setDisplayErrorMsg, setCreditCardSession, partnerName, fnName) {
	initialSetState({tempusIframeHasLoaded:false, transArmorTokenError: false});

	var sendDate = (new Date()).getTime();
	await fetchTimeout(creditCardIframeTokenUrl, {
		method: "POST",
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'x-ibm-client-id':xIBMClientId,
			'X-IBM-CLIENT-SECRET': xIBMClientSecret,
			'authorization': 'Bearer ' + oauthToken
		},
	}, 60000)	
	.then(response => response.json()).then((dataArray) => {
		if (fnName.toLowerCase() !== 'edit'){
			var receiveDate = (new Date()).getTime();
			var responseTimeMs = receiveDate - sendDate;
			trackResponseTime(responseTimeMs, 'CreditCardToken service response time');
		}
		var cardno_hardstop_data = {
		          event_parent: "Page Tag",
		          event_type: "Hard Stop",
		          event_id: "We're sorry, we're having technical difficulties processing credit cards right now. Please try again later or use alternative payment method.", "eventvalue":"Card iframe downtime",
		          da_track: "true",
		          event_value: "CreditCardToken Service Down",
		          page_name: "Payment Details Page",
		          external_partner_name: partnerName,
		          product_type: "Workers Compensation",
		};
		if(dataArray.httpCode ==="500" && (dataArray.httpMessage === "Internal Server Error") || (dataArray.httpMessage && dataArray.httpMessage.includes("Open Error")) ){
			window._trackAnalytics(cardno_hardstop_data);
			setTimeout(()=> {
				setDisplayErrorMsg(true);
			}, 2000)
		}

		if(dataArray.httpCode ==="400" && dataArray.httpMessage === "Badaccesstoken"){
			window._trackAnalytics(cardno_hardstop_data);
			setTimeout(()=> {
				setDisplayErrorMsg(true);
			},1000);

			setTimeout(()=> {
				setCreditCardSession(false);
			},2000);
		}

		setiframeUrl( dataArray.iframeurl);
		//partnername, eventid, eventvalue, pagename
	}).catch(error => {	
		if (fnName.toLowerCase() === 'edit'){
			var receiveDate = (new Date()).getTime();
			var responseTimeMs = receiveDate - sendDate;
			trackResponseTime(responseTimeMs, 'CreditCardToken service response time');
		}
		setDisplayErrorMsg(true);

	});
}

function successEvents(partnerName, eventId, eventValue, pageName){
	_dl = {
			"site_name": " HIGPayment",
			"page_name": pageName,
			"external_partner_name": partnerName,
			"product_type":"Workers Compensation"
			};
	window.utag.view(_dl); 
	var success_data = {
		"event_parent" : "Page Tag", 
        "event_type" : "content impression", 
        "event_id":  eventId,
        "da_track": "true",
        "event_value": eventValue,
        "site_name": " HIGPayment",
		"page_name": pageName,
		"product_type":"Workers Compensation"
    }
    window._trackAnalytics(success_data);	
}

function trackResponseTime(responseTimeMs, serviceName){
	var responsetime_data = { 
		"event_parent" : "Page Tag", 
		"event_type" : "content impression", 
		"event_id":  serviceName,
		"event_value": String(responseTimeMs) + "ms",
		"page_name": "Payment Details Page",
		"site_name": " HIGPayment",
		"product_type":"Workers Compensation",
		"da_track" : "true"
		}
	 window._trackAnalytics(responsetime_data);
}

export function paramPrefixEncoding(url){
	return url.indexOf('?') > 0 ? "&" : "?";
}

export function getCAPrivacyPolicy() {
		return hrefCAPrivacyPolicy;
}