import React, { useContext } from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext'

const Header = () => {
    const { state } = useContext(InitialLoadContext);    
    if (state.eqsIssuanceInd == 'true') {    
        return <></>; 
    }         
    else {        
        return (
            <header className="header-minimal">
                <div className="container">
                    <div className="row">
                        <a className="logo navbar-brand" href="index.html">
                            <img alt="The Hartford Logo - " src="static/images/logo.svg" />
                        </a>
                        {
                            state.partnerName &&
                            <p className="partner-name">In partnership with {state.partnerName.toUpperCase()}</p>
                        }
                    </div>
                </div>
            </header>
        )
    }

}

export default Header;