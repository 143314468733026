import React from 'react';
import { useEffect } from 'react';

import LineItem from './LineItem';

const policyTypes_data_dl = {"id":"Policy Type","location":"HIG Payment Page","type":"impression","da_track":"true"};
const todaysPaymentTotal_data_dl = {"id":"Today's Payment Total","location":"HIG Payment Page","type":"impression","da_track":"true"}; 
const LineItemsList = ({lineItems}) => {

    useEffect(()=>{
        if(lineItems !== ''){
            var filteredPolicys = lineItems.filter((i) => {return i.title !== "Today's Payment Total"}).map((f) => {return f.title}).join(" | ");
            var policyTypesDataDl = {
                    "event_parent":"Page Tag",
                    "event_type": policyTypes_data_dl.type,
                    "event_id": policyTypes_data_dl.id,
                    "event_value": filteredPolicys,
                    "da_track": policyTypes_data_dl.da_track,
                    "page_name": "Payment Details Page"
                }
                window._trackAnalytics(policyTypesDataDl);
            }

            if(lineItems !== ''){
                var todaysPayment = lineItems.filter((i) => {return i.title === "Today's Payment Total"});
                if(todaysPayment.length > 0){
                    var todaysPaymentDataDl = {
                            "event_parent":"Page Tag",
                            "event_type": todaysPaymentTotal_data_dl.type,
                            "event_id": todaysPaymentTotal_data_dl.id,
                            "event_value": todaysPayment[0].price,
                            "da_track": todaysPaymentTotal_data_dl.da_track,
                            "page_name": "Payment Details Page"
                        }
                        window._trackAnalytics(todaysPaymentDataDl);
                    }
                }
    }, []);
    
    return (
        <ul className="line-items" tealium-impression data-dl={JSON.stringify(policyTypes_data_dl)}>
            {lineItems.map(item => {return (<LineItem key={item.title} data={item}/>)})}
        </ul>
    )
}

export default LineItemsList;