import React, { useContext, useEffect } from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext';
import { showProcessingPage } from '../../utils/showPages';
import { postChildIframeMessage } from '../../utils/services'

const ProcessingPage = (props) => {
    const { state } = useContext(InitialLoadContext);
    const showPage = showProcessingPage(state);
    useEffect(() => {
        if (showPage) {
            props.appRef.current.classList.add('loading');
        } else {            
            props.appRef.current.classList.remove('loading');        
            postChildIframeMessage('AppLoaded', undefined, undefined, undefined, state);
        }

    }, [showPage, props.appRef])
    return (
        <>
            {
                showPage &&
                <div className="processing-page">
                    <h1 className="processing-page-title">One Moment Please</h1>
                    <em className="icon icon-spinner icon-pulse"></em>
                </div>
            }
        </>
    );
}

export default ProcessingPage;