import React from 'react';

import CardTypeDisplay from './CardTypeDisplay';

const CardPaymentHeader = ({cardType}) => {
    return(
        <div className="payment-header">
            <h3 className="payment-header__title"><i className="icon icon-secure"></i>Secure Payment Details</h3>
            <CardTypeDisplay cardType={cardType}/>
        </div>
    )
}

export default CardPaymentHeader;