import React, { useState, useContext } from 'react';
import { useEffect } from 'react/cjs/react.production.min';
import { ChoosePaymentContext } from '../Contexts/ChoosePaymentModuleContext';

const FraudWarningMessage = () => {
    const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(false);
    const { state, setState } = useContext(ChoosePaymentContext);

    const handleChange = event => {
        let checkString = "";
        if (event.target.checked) {
            setState({isCheckBoxClicked: true})
            checkString = "Checked"
        } else {
            setState({isCheckBoxClicked: false})
            checkString = "Unchecked"
        }
        setIsCheckBoxClicked(current => !current);
        var data = { 
            "event_parent" : "Page Tag", 
            "event_type" : "Checkbox", 
            "event_id":  "NY Fraud message OR Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation",
            "event_value": checkString,
            "da_track": "true",
            "page_name": "Payment Details Page"
            }
        
        window._trackAnalytics(data);
    };

    return (
        <div className="fraud-warning-msg-padding textJustifyDivClass">
            <p className="fraud-warning-text-msg"><b>Fraud Warning:</b> Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of
                claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto,
                commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and
                the stated value of the claim for each such violation.</p>
            <label>
                <input
                    type="checkbox"
                    value={isCheckBoxClicked}
                    onChange={handleChange}
                    id="fraud-warning-checkbox"
                    name="fraud-warning-checkbox"
                />
                <span className="fraud-warning-text-msg"> I have read and agree to the Fraud Warning</span>
            </label>
        </div>
    )
}

export default FraudWarningMessage;