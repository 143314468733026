import React, { useState, useContext, useEffect, useRef } from 'react';

import CardPaymentHeader from './CardPaymentHeader';
import CardInfoForm from './CardInfoForm';
import { InitialLoadContext } from '../../Contexts/InitialLoadContext';
import { ChoosePaymentContext } from '../../Contexts/ChoosePaymentModuleContext';
import { callPostCreditCardToken } from '../../../utils/services';
import {cardno_hardstop_data_dl} from './form-groups/CardNumberFormGroup';

const CardPaymentBlock = ({ handleOnResize }) => {
    const [cardType, setCardType] = useState('unknown');
    const { state, setState } = useContext(InitialLoadContext);
    const { state: chooseState, setState: setChooseState } = useContext(ChoosePaymentContext);
    const intervalRef = useRef();
    const updateCardType = (newCardType) => {
        setCardType(newCardType);
    }
    const setiframeUrl = (url) => {
        setState({ iframeUrl: url })
    }
    const setDisplayErrorMsg = (val) => {
        setState({ dispalyErrorMsg: val })
    }

    const setCreditCardSession = (value) => {
        setState({ creditCardSession: value })
    }


    const iframeTokeHandler = () => {
        callPostCreditCardToken(
            setiframeUrl,
            state.OauthToken,
            setState,
            setDisplayErrorMsg,
            setCreditCardSession,
            state.partnerName,
            'onload'
        );
    };

    useEffect(() => {
        clearInterval(intervalRef.current);
        if (chooseState.paymentType.toLowerCase() !== 'eft') {

            setiframeUrl('');
            iframeTokeHandler();
            intervalRef.current = setInterval(() => {
                setiframeUrl('');
                iframeTokeHandler();
            }, 900000);
        }

    }, [chooseState.paymentType])

    useEffect(() => {
        handleOnResize();
    }, [state.iframeUrl, state.iframeError]);

    useEffect(() => {
        if (state.creditCardSession === false) {
            localStorage.setItem("setCreditCardSession", false);
        }
        else {
            localStorage.setItem("setCreditCardSession", true);
        }
    }, [state.creditCardSession])

    return (
        <div>
            {
                !state.iframeUrl && !state.dispalyErrorMsg &&
                <div className="iframe-spinner-container">
                    <em
                        className="spinner-icon icon icon-spinner icon-pulse"
                    ></em>
                </div>

            }

            {
                !!state.dispalyErrorMsg && !state.iframeUrl &&
                <div className="iframeload-error-message">
                    We're sorry, we're having technical difficulties processing credit cards
                    right now. Please try again later or use an alternative payment method.
                </div>
            }
            {
                !!state.iframeUrl && !state.dispalyErrorMsg &&
                <>
                    <CardPaymentHeader cardType={cardType} />
                    <CardInfoForm updateCardType={updateCardType} handleOnResize={handleOnResize} />
                </>
            }



        </div>
    )
}

export default CardPaymentBlock;