import React, { useContext, useEffect, useRef } from 'react';

import { ChoosePaymentContext } from '../Contexts/ChoosePaymentModuleContext';
import { getBankingStateValidationData } from '../../utils/ChoosePaymentModule';
import { callSavePaymentInformation } from '../../utils/services';
import { InitialLoadContext } from '../Contexts/InitialLoadContext';
const continue_data_dl = { "id": "Continue", "location": "HIG Payment Page", "type": "Button Activity" };
const ContinueButton = () => {
  const { state, setState } = useContext(ChoosePaymentContext);
  const initialContext = useContext(InitialLoadContext);
  const initialState = initialContext.state;
  const alignErrorText = useRef();
  const isPaymentFrameInd = state.paymentFrameInd == 'true';
  const handleOnSubmit = (e) => {
    const { valid, newErrorStates } = getBankingStateValidationData(state, initialState);
    let stopSubmission = false;

    if (!valid) {
      stopSubmission = true;
      setState(newErrorStates)
    }

    if (stopSubmission) {
      e.preventDefault();
    } else {

      //save payment information
      if (!state.paymentInformationSent) {
        var contineButton_data = {
          "event_parent": "Page Tag",
          "event_type": continue_data_dl.type,
          "event_id": continue_data_dl.id,
          "event_value": window.location.hostname.concat(window.location.pathname),
          "page_name": "Payment Details Page",
          "external_partner_name": initialState.partnerName,
          "da_track": "true",
          "product_type": "Workers Compensation"
        };
        window._trackAnalytics(contineButton_data);
        callSavePaymentInformation(state, initialState);
      }
      setState({ submittingPaymentInformation: true, paymentInformationSent: true });
      e.preventDefault();
    }
  }

  const ccErrormessageHandling = () => {
    if (initialState.creditCardFieldSession == false
      && state.paymentType == '005'
      && initialState.crediCardDisplayErrorMsg == true) {
      alignErrorText.current.click();
    }
  }

  useEffect(() => {
    ccErrormessageHandling();
  }, [
    initialState.creditCardFieldSession,
    initialState.crediCardDisplayErrorMsg,
    state.paymentType
  ]);

  if (initialState.eqsIssuanceInd == 'true') {    
    return (
      <button ref={alignErrorText} className="btn btn-primary btn-block continue-btn" onClick={handleOnSubmit} disabled={state.submittingPaymentInformation || (initialState.stateCd ==='NY' && !state.isCheckBoxClicked)} tealium-link data-dl={JSON.stringify(continue_data_dl)}>
        <span>{state.submittingPaymentInformation ? "Issuing..." : "Buy Now"}</span>
      </button>
    )  
  } else {    
    return (
      <button ref={alignErrorText} className="btn btn-primary btn-block continue-btn" onClick={handleOnSubmit} disabled={state.submittingPaymentInformation || (initialState.stateCd ==='NY' && !state.isCheckBoxClicked)} tealium-link data-dl={JSON.stringify(continue_data_dl)}>
        <span>{state.submittingPaymentInformation  ? "Saving..." : "Continue"}</span>
      </button>
    )
  }

}

export default ContinueButton;