import React, { useState, useRef, useEffect } from "react";
import "./App.scss";

import InitialLoadContextWrapper from "./components/Contexts/InitialLoadContext";
import Header from "./components/Header";
import GenericErrorPage from "./components/GenericErrorPage";
import PaymentUIPage from "./components/PaymentUIPage";
import Footer from "./components/Footer";
import SessionTimeOut from "./components/SessionTimeOut";
import ProcessingPage from "./components/ProcessingPage";
import GlassBox from "./components/Glassbox";
import * as Constants from './utils/Constants';
import { updateEqsAppBackgroundColor } from "./utils/updateEqsAppBackground";

var errorMessage = "";
var wcErrorMessage = "";
var bopErrorMessage = "";
var wcDuplicateParams = false;
var bopDuplicateParams = false;

function getAllQueryParams() {
  function isValidQueryString(str) {
    return (/^((?![<>]).)*$/).test(str);
  }
  function filterQueryString(str) {
    let filteredString = str.replace(/[<>]/g, '');
    return filteredString;
  }
  var query = filterQueryString(window.location.href);
  if (isValidQueryString(query)) {
    query = query.split('?');
  } else {
    query = [''];
  }

  var search;
  if (query.length === 1) {
    search = "";
  } else {
    search = filterQueryString(query[1]);
  }
  var queryStr = encodeURI(search).split('&');

  if (queryStr === "" || queryStr[0] === "") {
    return {};
  }
  var params = {};
  for (var i = 0; i < queryStr.length; ++i) {
    var paramArr = queryStr[i].split('=', 2);
    if (paramArr.length === 1) {
      params[paramArr[0]] = "";
    } else {
      if (paramArr[1] === 'true') {
        params[paramArr[0]] = true;
      } else if (paramArr[1] === 'false') {
        params[paramArr[0]] = false;
      } else {
        if (!(paramArr[0] in params)) {
          params[paramArr[0]] = decodeURIComponent(filterQueryString(paramArr[1].replace(/\+/g, " ")));
        } else {
          setDuplicateErrorMessage(paramArr[0]);
        }
      }
    }
  }
  return params;
}

function createErrorMessage(param) {
  switch (param) {
    case "tokenId":
      errorMessage = "Claim%20Check%20Token%20Is%20Missing";
      postChildErrorMessage("Error", "Claim Check Token Is Missing", undefined, undefined);
      break;
    default:
      console.log("required query parameters are not available");
  }
  return errorMessage;
}

export function postChildErrorMessage(eventType, errorMsg, wcErrorMsg, bopErrorMsg) {
  if (window.location !== window.parent.location) {
    const obj = { type: eventType, messages: { errorMessage: errorMsg, wcErrorMessage: wcErrorMsg, bopErrorMessage: bopErrorMsg } };
    const myJSON = JSON.stringify(obj);
    window.parent.postMessage(myJSON, '*');
  }
}

function checkQueryParams(qParameters, param) {
  if (errorMessage === "") {
    let found = false;
    for (const property in qParameters) {
      if (property === param) {
        if (!qParameters[param]) {
          errorMessage = createErrorMessage(param);
        }
        found = true;
        break;
      }
    }
    if (!found) {
      errorMessage = createErrorMessage(param);
    }
  }
}

function setDuplicateErrorMessage(param) {
  if (param.indexOf('wc') !== -1) {
    wcDuplicateParams = true;
  } else if (param.indexOf('bop') !== -1) {
    bopDuplicateParams = true;
  }
}

function App() {
  const appRef = useRef();
  const qParameters = getAllQueryParams();
  const totalParams = [
    "tokenId"
  ];
  const [sessionInActive, setSessionInactive] = useState(false);
  const [sessionCreditCardState, setSessionCreditcardSession] = useState(true);


  useEffect(async () => {
    localStorage.removeItem('setCreditCardSession');
    const Interval = setInterval(() => {

      if (localStorage.getItem("setCreditCardSession") === "true"
        || localStorage.getItem("setCreditCardSession") === null
        || localStorage.getItem("setCreditCardSession") === undefined) {
        setSessionCreditcardSession(true);

      }
      else {
        setSessionCreditcardSession(false);

      }
    }, 60000);

    return () => clearInterval(Interval);

  }, []);

  totalParams.forEach((param, index) => {
    checkQueryParams(qParameters, param);

  });

  function checkEQSInd(tokenData) {
    if (tokenData && tokenData.length > 1) {
      const status = tokenData[0] || 0;
      const data = tokenData[1] || {};
      const eqsIssuanceInd = data?.eqsIssuanceInd || false;
      if (status && status === Constants.HTTP_200) {
        updateEqsAppBackgroundColor(eqsIssuanceInd);
      }
    }
  }

  return (
    <InitialLoadContextWrapper params={qParameters} errorMessage={errorMessage} wcErrorMessage={wcErrorMessage} bopErrorMessage={bopErrorMessage}
      wcDuplicateParams={wcDuplicateParams} bopDuplicateParams={bopDuplicateParams} checkEQSInd={checkEQSInd}>
      <div className={`App ${!!document.documentMode ? 'is-ie' : ''}`} ref={appRef}>
        <Header />
        <div className="container">
          <div className="row">
            <div className="main-container">
              {!sessionInActive && (
                <React.Fragment>
                  <ProcessingPage appRef={appRef} />
                  <GenericErrorPage appRef={appRef} />
                  <PaymentUIPage />
                </React.Fragment>
              )}
              <SessionTimeOut
                setSessionInactive={setSessionInactive}
                creditCardSessionProps={sessionCreditCardState}
              />
              <GlassBox />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </InitialLoadContextWrapper>
  );

}

export default App;
