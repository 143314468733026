import React from 'react';

const ModalBackground = ({show, fadeIn}) => {

    return (
        <React.Fragment>
            {
                show &&
                <div 
                    className={fadeIn ? "modal-backdrop fade in" : "modal-backdrop fade"}>
                </div>
            }
        </React.Fragment>
    )

}

export default ModalBackground;