import React from 'react';

export const ChoosePaymentContext = React.createContext();

const cardno_error_data_dl = { "eventid":"Please enter a valid card number","eventparent": "Page Tag", "eventtype": "Field Level Message", "eventvalue": "Card Number", "da_track":"true" };

class ChoosePaymentContextWrapper extends React.Component {
    state = {
        paymentType: 'EFT',
        nameOnAccount: '',
        nameOnCard: '',
        nameOnAccountErrors: [],
        accountType: '',
        accountTypeErrors: [],
        routingNumber: '',
        routingNumberErrors: [],
        accountNumber: '',
        accountNumberErrors: [],
        cardType: 'unknown',
        nameOnCardErrors: [],
        cardNumber: '',
        cardNumberErrors: [],
        expirationMonth: '',
        expirationYear: '',
        expirationErrors: [],
        tooltipAnimating: false,
        showHelpfulTooltip: false,
        bankName: '',
        bankNameIsLoading: false,
        submittingPaymentInformation: false,
        paymentInformationSent: false,
        creditCardSession: true,
        isCheckBoxClicked: false
    }

    constructor(props) {
        super();
        this.setState = this.setState.bind(this);
        this.state.showBankOnly = props.showBankOnly;
    }

    componentDidMount() {
        const that = this;
        window.addEventListener('message', (e) => {
            const message = e.data;
            if (message && typeof message === 'string' && message.toLowerCase() === 'error') {
                that.setState({ cardNumberErrors: ['Please enter a valid card number'] });
                var cardno_error_data = {
                    event_parent: "Page Tag",
                    event_type: cardno_error_data_dl.eventtype,
                    event_id: cardno_error_data_dl.eventid,
                    da_track: "true",
                    event_value: "Card number",
                    page_name: "Payment Details Page",
                    product_type: "Workers Compensation",
                  };
                window._trackAnalytics(cardno_error_data);
            }
        })
    }

    render() {
        return (
            <ChoosePaymentContext.Provider value={{ state: this.state, setState: this.setState }}>
                {this.props.children}
            </ChoosePaymentContext.Provider>
        )
    }
}

export default ChoosePaymentContextWrapper;