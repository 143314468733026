import React, { useContext } from 'react';

import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';
import { isValidAccountNumber, getAccountNumberErrors, bankAccountNumberMaxLength, isNumeric } from '../../../../utils/ChoosePaymentModule';
import { allowAlphaNumericOnKeypress } from '../../../../utils/KeyPress';
const accountno_data_dl = {"id":"Account Number","location":"HIG Payment Page","type":"input field","da_track":"true"};
export const accountno_error_data_dl = {"eventparent":"Page Tag","eventtype":"Field Level Message","eventvalue":"Account Number"};
const AccountNumberInput = ({ onTooltipClick }) => {
    const { state, setState } = useContext(ChoosePaymentContext);

    const handleOnChange = (e) => {
      const newValue = e.target.value;
      if (isNumeric(newValue)) {
        setState({ accountNumber: newValue });
      } else {
        let errors = [];
        if (!isValidAccountNumber(newValue)) {
          errors = getAccountNumberErrors(newValue);
        }
        setState({ accountNumberErrors: errors });
      }
    };

    const handleOnBlur = () => {
        let errors = [];
        if (!isValidAccountNumber(state.accountNumber)) {
            errors = getAccountNumberErrors(state.accountNumber);
        }
        setState({ accountNumberErrors: errors });
        if (errors.length>0){
            var accountno_error_data = {
                "event_parent" : accountno_error_data_dl.eventparent, 
                "event_type" : accountno_error_data_dl.eventtype, 
                "event_id":  errors[0],
                "da_track": accountno_data_dl.da_track,
                "event_value": accountno_error_data_dl.eventvalue,
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            };
            window._trackAnalytics(accountno_error_data);
        } else {
            var accountno_data = {
                "event_parent" : "Page Tag",
                "event_type" : accountno_data_dl.type,
                "event_id":  accountno_data_dl.id,
                "da_track": accountno_data_dl.da_track,
                "event_value": window.location.hostname.concat(window.location.pathname),
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            };
            window._trackAnalytics(accountno_data);
        }
    }

    return (
        <div className="banking-info__input-container">
            <label className="control-label" htmlFor="AccountNumberInput">Account number
                {
                    !!onTooltipClick &&
                    <span className="tooltip-soft-text">
                        <i className="icon icon-info tooltip-soft-icon" onClick={onTooltipClick}></i>
                    </span>
                }

            </label>
            <input
                id="AccountNumberInput"
                tealium-change data-dl={JSON.stringify(accountno_data_dl)}
                className="form-control"
                type="text"
                inputMode="numeric"
                value={state.accountNumber}
                aria-required="true"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onKeyPress={allowAlphaNumericOnKeypress.bind(null, { allow: [], maxLength: bankAccountNumberMaxLength })}
            />
        </div>
    )
}

export default AccountNumberInput;