import React, { useState, useContext } from 'react';
import FormGroup from '../../../FormGroup';
import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';
import { BANK_NAME_REGEX } from '../../BankPaymentBlock/form-groups/BankAccountOwnerFormGroup';
const nameoncard_data_dl = { "id": "Name on card", "location": "HIG Payment Page", "type": "input field", "da_track": "true" };
const nameoncard_error_data_dl = { "eventparent": "Page Tag", "eventtype": "Field Level Message", "eventvalue": "Name on card", "da_track": "true" };
const CardOwnerFormGroup = (props) => {
    const [name, setName] = useState("");
    const { state, setState } = useContext(ChoosePaymentContext);

    const validateOwnerName = (name) => {
        return name.length <= 40 && name.length > 0;
    }

    const isSpecialAllowedChar = (name) => {
        return name.match(BANK_NAME_REGEX);
    }

    const handleCardNameChange = (e) => {
        const newNameValue = e.target.value;

        if ((validateOwnerName(newNameValue) && isSpecialAllowedChar(newNameValue))) {
            setState({ nameOnCardErrors: [] });
            setState({ nameOnCard: newNameValue });
            if (e.type.toLowerCase() === 'blur') {
                var nameoncard_data = {
                    "event_parent": "Page Tag",
                    "event_type": nameoncard_data_dl.type,
                    "event_id": nameoncard_data_dl.id,
                    "da_track": nameoncard_data_dl.da_track,
                    "event_value": window.location.hostname.concat(window.location.pathname),
                    "page_name": "Payment Details Page",
                    "external_partner_name": state.partnerName,
                    "product_type": "Workers Compensation"
                };
                window._trackAnalytics(nameoncard_data);
            }
        } else if (newNameValue.length === 0 && e.type.toLowerCase() !== 'blur') {
            setState({ nameOnCard: newNameValue });
        }
        else {
            if (e.type.toLowerCase() === 'blur') {
                getNameOnCardErrors();
            }
        }

    }

    const getNameOnCardErrors = () => {
        let errors = ['Please enter a valid name on card'];
        setState({ nameOnCardErrors: errors });
        var nameoncard_error_data = {
            "event_parent": nameoncard_error_data_dl.eventparent,
            "event_type": nameoncard_error_data_dl.eventtype,
            "event_id": errors[0],
            "da_track": nameoncard_error_data_dl.da_track,
            "event_value": nameoncard_error_data_dl.eventvalue,
            "page_name": "Payment Details Page",
            "external_partner_name": state.partnerName,
            "product_type": "Workers Compensation"
        }
        window._trackAnalytics(nameoncard_error_data);
    }
    return (

        <FormGroup errors={state.nameOnCardErrors} tealium-impression data-dl={JSON.stringify(nameoncard_error_data_dl)}>
            <div className="form-group has-feedback" style={{ margin: '0px' }}>
                <label htmlFor="name-on-card" className="control-label">Name on card</label>
                <div className="input-group">
                    <span className="input-group-addon"><i className="icon icon-user"></i></span>
                    <input
                        id="name-on-card"
                        tealium-change data-dl={JSON.stringify(nameoncard_data_dl)}
                        type="text"
                        className="form-control"
                        name="name"
                        data-error="Please enter your name."
                        aria-required="true"
                        required=""
                        onBlur={handleCardNameChange}
                        value={state.nameOnCard}
                        onChange={handleCardNameChange}
                    />
                </div>
                {/* <span className="icon icon-attention form-control-feedback" aria-hidden="true"></span> */}
            </div>
        </FormGroup>
    )
}

export default CardOwnerFormGroup;