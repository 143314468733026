import React, { useEffect, useContext } from 'react';
import { InitialLoadContext } from '../../../Contexts/InitialLoadContext';

const bankname_data_dl = {"id":"Bank Name","location":"HIG Payment Page","type":"impression","da_track":"true"};
const BankDisplayName = (props)=>{
    const { state } = useContext(InitialLoadContext);
    const isLoading = props.isLoading;
    const bankName = props.bankName;
    useEffect(()=>{
    if (bankName!=='' && state.partnerName!==''){
        var bankname_data = { 
            "event_parent" : "Page Tag", 
            "event_type" : bankname_data_dl.type, 
            "event_id":  bankname_data_dl.id,
            "event_value": bankName,
            "da_track":bankname_data_dl.da_track,
            "page_name" : "Payment Details Page",
            "external_partner_name": state.partnerName,
            "product_type":"Workers Compensation"
            };
        window._trackAnalytics(bankname_data);
    }
    },[bankName, state.partnerName]);
    
    return (
    <div className="bank-display-name">
        {!isLoading && !!bankName &&
           <p tealium-impression data-dl={JSON.stringify(bankname_data_dl)}> {bankName} </p> 
        }
        
        {isLoading && 
            <i className="icon icon-spinner icon-pulse"></i>
        }
        
    </div>);
}
export default BankDisplayName;
