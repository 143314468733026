import React, { useContext, useEffect } from 'react';

import LineItemsList from '../LineItems/LineItemsList';
import { InitialLoadContext } from '../Contexts/InitialLoadContext';
import { getListItems } from '../../utils/TodaysPaymentModule';
export const wcPremium_data_dl = { "id": "today's payment", "location": "HIG Payment Page", "type": "impression", "da_track": "true", "product_type": "Workers Compensation", "page_name": "Payment Details Page", };
const TodaysPaymentModule = () => {
    const { state } = useContext(InitialLoadContext);
    let premium = getListItems(state, showTotalPaymentBlock());

    function showTotalPaymentBlock() {
        if (state.bopLob === "BOP" && state.lob === "WC") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className="todays-payment__container">
            <h2 className="section-title">Today's Payment</h2>
            <div className="section-wrapper">
                {state.eqsIssuanceInd
                    ? <i className="icon icon-money eqs-section-icon" alt="Dollar sign wrapped in circle icon"></i>
                    : <i className="icon icon-money section-icon" alt="Dollar sign wrapped in circle icon"></i>}
                {/* <p className="todays-payment__intro">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu bibendum eros. Vivamus convallis 
                leo sit amet elit tincidunt, ut ultricies dolor scelerisque.
                </p> */}
                <LineItemsList lineItems={premium} />
            </div>
        </div>
    )
}

export default TodaysPaymentModule;