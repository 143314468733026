import { BANK_NAME_REGEX } from "../components/ChoosePaymentModule/BankPaymentBlock/form-groups/BankAccountOwnerFormGroup";
export const nameOnAccountMaxLength = 35;
export const maxLengthNameCreditCard =40;
export const routingNumberLength = 9;
export const bankAccountNumberMaxLength = 17;

// const isAlpa = (value) => {
//     value = "" + value;
//     return /^[a-zA-z]+$/.test(value);
// }

export const isNumeric = (value) => {
    value = "" + value;
    return /^\d*$/.test(value);
}

export const isAlphaNumeric = (value) => {
    value = "" + value;
    return /^[\da-zA-z\s]+$/.test(value);
}

export const isSpecialCharacter = (value) =>{
    value = "" + value;
    return   /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
}

export const isValidRoutingNumber = (number) => {
    number = "" + number;
    const isValidInput = isAlphaNumeric(number);
    return number.length === routingNumberLength && isValidInput;
}

export const getRoutingNumberErrors = (number) => {
    number = "" + number;
    return ['Please enter a valid Bank routing number.'];
}

export const isValidAccountNumber = (number) => {
    number = "" + number;
    const isValidInput = isNumeric(number);
    return number.length <= bankAccountNumberMaxLength && isValidInput && number.length > 0;
}

export const getAccountNumberErrors = (number) => {
    number = "" + number;
    return ['Please enter a valid Bank Account Number.'];
}

export const isValidNameOnAccount = (name, maxLength) => {
    const isValidInput = name.match(BANK_NAME_REGEX);
    return name.length <= maxLength && isValidInput && name.length > 0;
}

export const getNameOnAccountErrors = (name) => {
    return ['Please enter a valid Name on bank account'];
}

export const isValidBankingType = (type) => {
    return type === 'savings' || type === 'checking';
}

export const getBankingTypeErrors = (type) => {
    return ['Please select a valid account type'];
}

export const nameOnCardValid = (name) => {
    return name=="";
}

export const getNameOnCardErrors = (name) => {
    return ['Please enter a valid name on card'];
}

export const expirationDateValid = (expirationMonth, expirationYear) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    return ((Number.parseInt(expirationYear)==currentYear && Number.parseInt(expirationMonth) <currentMonth) || (expirationMonth == "") || (expirationYear == "")  ||(expirationYear == "year") || (expirationMonth == "MM") )  
}

export const getExpirationError = () => {
    return ['Please enter a valid expiration date'];
}

export const checkABARoutingErrorResponse = (error) => {
    if(error && error.length > 0 && error[0] === 'We cannot identify a bank associated with the routing number you entered. Please check the number and try again'){
        return true;
    } else {
        return false;
    }
}

export const getBankingStateValidationData = (state, initialState) => {

    if(state.paymentType.toUpperCase() === "EFT"){
        const nameValid = isValidNameOnAccount(state.nameOnAccount, nameOnAccountMaxLength );
        const typeValid = isValidBankingType(state.accountType);
        const routingValid = isValidRoutingNumber(state.routingNumber) && !checkABARoutingErrorResponse(state.routingNumberErrors);
        const accountValid = isValidAccountNumber(state.accountNumber);
        const newErrorStates = {
            nameOnAccountErrors: nameValid ? [] : getNameOnAccountErrors(state.nameOnAccount),
            accountTypeErrors: typeValid ? [] : getBankingTypeErrors(state.accountType),
            routingNumberErrors: routingValid ? [] : (state.routingNumberErrors.length!==0 ? state.routingNumberErrors : getRoutingNumberErrors(state.routingNumber)),
            accountNumberErrors: accountValid ? [] : getAccountNumberErrors(state.accountNumber),
        };

        const errorObj = {
            newErrorStates:newErrorStates,
            valid: nameValid && typeValid && routingValid && accountValid
        };
        return errorObj;
    }
    else if(state.paymentType == "005") {
        const nameValid = nameOnCardValid(state.nameOnCard);
        const expirationValid = expirationDateValid(state.expirationMonth, state.expirationYear);
        const tokenValid = (!!initialState.transArmorToken && (initialState.transuccess.toLowerCase() === 'true' || !initialState.transuccess));

        let expirationflag = true; 
        if(expirationValid){
            expirationflag = false;
        }

        let nameValidFlag = true;
        if(nameValid){
            nameValidFlag = false;
        }
        
        const newErrorStates = {
            nameOnCardErrors: nameValid ?getNameOnCardErrors(state.nameOnCard):[] ,
            expirationErrors: expirationValid?getExpirationError():[],
            cardNumberErrors: tokenValid ? [] : ['Please enter a valid card number']
        };
        const errorObj = { 
            newErrorStates:newErrorStates,
            valid: nameValidFlag && expirationflag && tokenValid
        };
        return errorObj;
    }    

}

export const getTrueCardType = (cardCode) => {
    switch(cardCode){
        case '001':
            return 'MC';
        case '002':
            return 'V';
        case '006':
            return 'AM1';
        case '003':
            return 'DC';
        default:
            return 'unknown';
    }
}