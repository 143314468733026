import React from 'react';
import { useEffect } from 'react';

const AuthenticationMessage = () => {

    useEffect(() =>{
        let authMsg_data = {
            "event_parent" : "Page Tag",
            "event_type" : "content impression",
            "event_id" : "Authorization Message",
            "da_track": "true",
            "event_value": window.location.href,
            "page_name": "Payment Details Page"
        }
        window._trackAnalytics(authMsg_data);
    },[]);

    return (
        <div className="auth-msg-line-break textJustifyDivClass" tabindex="0">
            <p className="auth-msg-heading">Small Business Owner:</p>
            <p className="auth-msg-body">By clicking Continue, I/we authorize The Hartford Fire Insurance Company and its affiliated companies (hereinafter called The Hartford), to debit the bank account or charge the credit card listed in the amount of the premium for the insurance policy(ies) shown above. This authorization applies to a one-time withdrawal from a bank account or charge to a credit card (down payment or pay-in-full) or the establishment of a repetitive electronic fund transfer (EFT) payment plan. This authorization will remain in effect until The Hartford receives notice of its termination in time so that The Hartford and the financial institution have a reasonable opportunity to process the termination.  I/we understand that this authorization is being provided using an electronic signature, that this authorization carries the same legal effects as a paper signature, and that I/we have read and agree to the terms of this authorization.</p>
            <p className="auth-msg-heading">Agent:</p>
            <p className="auth-msg-body">By clicking Continue, you are agreeing to the following statement: I have obtained the insured's authorization for The Hartford Fire Insurance Company and its affiliated companies to initiate withdrawals from the insured's bank account or to charge the insured's credit card to pay the down payment and / or set up a repetitive electronic funds transfer payment plan (Auto Pay).  The authorization will remain in effect until The Hartford receives notice of its termination in time so that The Hartford and the financial institution have a reasonable opportunity to process the termination.</p>
        </div>
    )
}

export default AuthenticationMessage;