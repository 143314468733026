import React, { useContext, useEffect } from 'react';

import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';
import FormGroup from '../../../FormGroup';
import BankRoutingInput from './BankRoutingInput'
import AccountNumberInput from './AccountNumberInput';
import BankDisplayName from "./BankDisplayName";
import { callBankABAValidationService } from '../../../../utils/services';
import { InitialLoadContext } from '../../../Contexts/InitialLoadContext';
import {routing_error_data_dl} from './BankRoutingInput';
import {accountno_error_data_dl} from './AccountNumberInput';

const AccountInformationFormGroup = ({ toggleHelpfulTooltip }) => {
    const { state, setState } = useContext(ChoosePaymentContext);
    const { state:initialLoadState } = useContext(InitialLoadContext);
    useEffect(() => {
        if (state.routingNumber.length === 9) {
            callBankABAValidationService(state, setState, initialLoadState.OauthToken);
        }
    }, [state.routingNumber, setState])
    return (
        <div className="account-form-groups">
            <FormGroup errors={state.routingNumberErrors} tealium-impression data-dl={JSON.stringify(routing_error_data_dl)}>
                <BankRoutingInput onTooltipClick={toggleHelpfulTooltip} />
                <BankDisplayName isLoading={state.bankNameIsLoading} bankName={state.bankName} />
            </FormGroup>
            <FormGroup errors={state.accountNumberErrors} tealium-impression data-dl={JSON.stringify(accountno_error_data_dl)}>
                <AccountNumberInput onTooltipClick={toggleHelpfulTooltip} />
            </FormGroup>
        </div>
    )
}

export default AccountInformationFormGroup;