export const toFloatValue = (value, decimalPlaces = 2) => {
  const newNum = value
    .toString()
    .replace(/[^\d.-]/g, "")
    .match(`^-?\\d+(?:\\.\\d{0,${decimalPlaces}})?`)[0];
  return parseFloat(newNum);
};

// fn to set down payment
function formatPayment(payment) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(payment);
}

const checkforMulitPolicyDirectBill = (wcBillingType, bopBillingType) => {
  const wcBilling = wcBillingType.replace("%20", ' ');
  const bopBilling = bopBillingType.replace("%20", ' ');
  if(wcBilling.toLowerCase() === "direct bill" && bopBilling.toLowerCase() === "direct bill"){
    return true;
  } else {
    return false;
  }
}

const checkforPayrollBilling = (billingType) => {
  const wcBillingType = billingType.replace("%20", ' ');
  if(wcBillingType.toLowerCase() === "payroll billing"){
    return true;
  } else {
    return false;
  }
}

const checkforMultiPolicyError = (lob, bopLob, wcPaymentValidationMessage, bopPaymentValidationMessage) => {
  if(lob === "WC" && bopLob === "BOP" && (wcPaymentValidationMessage !== 'Success' || bopPaymentValidationMessage !== 'Success')){
    return true;
  } else {
    return false;
  }
}

function getPaymentPlan(billingPlanCode, state) {
  let planDesc = "";
  switch (billingPlanCode) {
    case "01":
      planDesc = "Full Pay";
      break;
    case "02":
      planDesc = "Initial down payment for 2-Pay Plan";
      break;
    case "04":
      planDesc = "Initial down payment for 4-Pay Plan";
      break;
    case "10":
      planDesc = "Initial down payment for 10-Pay Plan";
      break;
    case "12":
      planDesc = "Initial down payment for 12-Pay Plan";
      break;
    default:
      break;
    //do nothing
  }

  if(!(checkforMulitPolicyDirectBill(state.wcBillingType, state.bopBillingType) || checkforPayrollBilling(state.wcBillingType) || checkforMultiPolicyError(state.lob, state.bopLob, state.wcPaymentValidationMessage, state.bopPaymentValidationMessage))){
    planDesc += "\n Payment will be processed when the policy is issued";
  } 

  return planDesc;
}

function getTitle(lob,eqsInd) {
  let title = "";
  switch (lob) {
    case "WORK":
    case "WC":
      title = "Workers' Compensation";
      break;
    case "GL":
    case "LiabOnly":
      title = "General Liability";
      break;
    case "PropOnly":
      if (eqsInd == 'true') {
        title = "Property";
      }
      else {
        title = "Property (contents only)";
      }
      break;
    case "PropLiab":
    case "BOP":
      if (eqsInd == 'true') {
        title = "Business Owners";
      }
      else {
        title = "Business Owners Policy";
      }
      break;
    default:
    	break;
  }
  return title;
}

const setErrorMessage = (callCenterPhoneNumber) => {
  let msg = '';
  if(callCenterPhoneNumber) {
    msg = `We're sorry; due to an error we can't issue this policy online right now. Please call us at ${callCenterPhoneNumber}.`;
  } else {
    msg = `We're sorry; due to an error we can't issue this policy online right now.`;
  }
  setLineItemErrorWebAnalytics();
  return msg;
}

function addWcDetailInList(state, listItems){
  let wcDetail = {};
  if((state.lob === "WORK" || state.lob === "WC")){
    if(state.wcBillingType.toLowerCase() === "direct bill" && state.downPayAmt !== ""){
      wcDetail = {
        title: getTitle(state.lob,state.eqsIssuanceInd),
        price: formatPayment(state.downPayAmt),
        subcontent: getPaymentPlan(state.wcBillingPlan, state),
        makeBold: false,
        isLoading:state.priceIsLoading,
        isTotal:true,
        error: false
      }
    } else if(checkforPayrollBilling(state.wcBillingType) && state.wcPaymentValidationMessage === 'Success'){
      wcDetail = {
        title: getTitle(state.lob,state.eqsIssuanceInd),
        price: '',
        subcontent: "Payroll Billed: No payment required now",
        makeBold: false,
        isLoading:state.priceIsLoading,
        isTotal:true,
        error: false
      }
    }else{
      wcDetail = {
        title: getTitle(state.lob,state.eqsIssuanceInd),
        price: '',
        subcontent: setErrorMessage(state.callCenterPhoneNumber),
        makeBold: false,
        isLoading:state.priceIsLoading,
        isTotal:true,
        error: true
      }
    }
    listItems.push(wcDetail)
  } 
}

function addBopDetailInList(state, listItems) {
  let bopDetail = {};
  let lob = '';
  let lobSubCd = '';
  if(state.bopLob === "BOP"){
    lob = state.bopLob;
    lobSubCd = state.bopTypeCode ? state.bopTypeCode : lob;
    if(state.bopDownPayAmt !== "" && state.bopBillingType.toLowerCase() === "direct bill"){
      bopDetail = {
        title: getTitle(lobSubCd, state.eqsIssuanceInd),
        price: formatPayment(state.bopDownPayAmt),
        subcontent: getPaymentPlan(state.bopBillingPlan, state),
        makeBold: false,
        isLoading:state.priceIsLoading,
        isTotal:true,
        error: false
      }
    } else {
      bopDetail = {
        title: getTitle(lobSubCd,state.eqsIssuanceInd),
        price: '',
        subcontent: setErrorMessage(state.callCenterPhoneNumber),
        makeBold: false,
        isLoading:state.priceIsLoading,
        isTotal:true,
        error: true
      }
    }
    listItems.push(bopDetail)
  }
}

function getTotalPaymentDesc (state) {
  var desc = '';
  if(checkforMulitPolicyDirectBill(state.wcBillingType, state.bopBillingType) && !checkforMultiPolicyError(state.lob, state.bopLob, state.wcPaymentValidationMessage, state.bopPaymentValidationMessage)){
    desc = "Payment will be processed when the policy is issued. Each down payment may appear as a separate charge on your statement.";
  } else {
    desc = "Payment will be processed when the policy is issued";
  }
  return desc;
}

function setLineItemErrorWebAnalytics(){
  var data = { 
    "event_parent" : "Page Tag", 
    "event_type" : "Warning message", 
    "event_id":  "We're sorry; due to an error we can't issue this policy online right now. Please call us as at XXX-XXX-XXXX weekdays X AM - X PM ET.",
    "event_value": window.location.href,
    "da_track": "true",
    "page_name": "Payment Details Page"
    }
  window._trackAnalytics(data);	
}

export const getListItems = (state, excludeTotal = false) => {
  const listItems = [];
  addWcDetailInList(state, listItems);
  addBopDetailInList(state, listItems);

  if (!excludeTotal) {
    listItems.push({
      title: "Today's Payment Total",
      price: formatPayment(state.totalPayment),
      subcontent: getTotalPaymentDesc(state),
      makeBold: true,
      isLoading:state.priceIsLoading,
      error: false
    });
  }

  return listItems;
};
