import React, { useContext, useCallback } from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext';
const cancelBtn_data_dl = {"id":"Cancel","location":"HIG Payment Page","type":"button activity","da_track":"true"};
const CancelButton = () => {
    const { state } = useContext(InitialLoadContext);

    const onClickCancelBtn = useCallback((e) => {
        e.preventDefault();
        var cancelBtnDataDl = { 
          "event_parent" : "Page Tag", 
          "event_type" : cancelBtn_data_dl.type, 
          "event_id":  cancelBtn_data_dl.id,
          "event_value": window.location.hostname.concat(window.location.pathname),
          "da_track":cancelBtn_data_dl.da_track,
          "page_name" : "Payment Details Page",
          "external_partner_name": state.partnerName,
          "product_type":"Workers Compensation"
          }
          window._trackAnalytics(cancelBtnDataDl);
          window.location.href=state.cancelURL;
    }, [state.cancelURL]);

    return (
        <a href={state.cancelURL} className="btn cancel-btn" tealium-link data-dl={JSON.stringify(cancelBtn_data_dl)} onClick={onClickCancelBtn}>
            <span>Cancel</span>
        </a>
    )
}

export default CancelButton;