import React, { useContext } from 'react';

import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';
import { getNameOnAccountErrors, nameOnAccountMaxLength } from '../../../../utils/ChoosePaymentModule';
import FormGroup from '../../../FormGroup';

export const BANK_NAME_REGEX = /^[a-zA-z0-9-?,\s~/|&'{}`!_//%:^/\\/;#/@/./*'()[\]]*$/;
const nameonaccount_data_dl = {"id":"Name on Bank Account","location":"HIG Payment Page","type":"input field","da_track":"true"};
const nameonaccount_error_data_dl = {"eventparent":"Page Tag","eventtype":"Field Level Message","eventvalue":"Name on bank account"};
const BankAccountOwnerFormGroup = () => {
    const { state, setState } = useContext(ChoosePaymentContext);

    const handleOnChange = (e) => {
        const newName = e.target.value;
        if (newName.match(BANK_NAME_REGEX) && newName.length <= nameOnAccountMaxLength) {
            setState({ nameOnAccount: newName })
        }
    };

    const handleOnBlur = () => {
        let errors = [];
        if (state.nameOnAccount.length > nameOnAccountMaxLength || state.nameOnAccount.length === 0) {
            errors = getNameOnAccountErrors(state.nameOnAccount);
        }
        setState({ nameOnAccountErrors: errors });

        if (errors.length>0){
            var nameonaccount_error_data = {
                "event_parent" : nameonaccount_error_data_dl.eventparent, 
                "event_type" : nameonaccount_error_data_dl.eventtype, 
                "event_id":  errors[0],
                "da_track": "true",
                "event_value": nameonaccount_error_data_dl.eventvalue,
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            }
            window._trackAnalytics(nameonaccount_error_data); 
        } else {
            var nameonaccount_data = {
                "event_parent" : "Page Tag",
                "event_type" : nameonaccount_data_dl.type,
                "event_id":  nameonaccount_data_dl.id,
                "da_track": nameonaccount_data_dl.da_track,
                "event_value": window.location.hostname.concat(window.location.pathname),
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
                };
            window._trackAnalytics(nameonaccount_data);
        }
    }

    return (
        <FormGroup errors={state.nameOnAccountErrors} 
        tealium-impression data-dl={JSON.stringify(nameonaccount_error_data_dl)}>
            <label htmlFor="name-on-account" className="control-label">Name on bank account</label>
            <div className="input-group">
                <span className="input-group-addon"><i className="icon icon-user"></i></span>
                <input
                    id="name-on-account"
                    tealium-change data-dl={JSON.stringify(nameonaccount_data_dl)}
                    type="text"
                    className="form-control"
                    name="name"
                    data-error="Please enter your name."
                    aria-required="true"
                    required=""
                    value={state.nameOnAccount}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
            </div>
        </FormGroup>
    )
}

export default BankAccountOwnerFormGroup;