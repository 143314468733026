import React, { useState, useRef, useEffect, useCallback } from 'react';

const Accordian = (props) => {
    const { isOpen, onOpen = () => { }, onClose = () => { } } = props;
    const innerAccordian = useRef();
    const [height, setHeight] = useState(0);
    const [initTriggered, setInitTriggered] = useState(false)

    const getNewHeight = useCallback(() => {
        const inner = innerAccordian.current;
        const height = parseFloat(inner.getBoundingClientRect().height);
        // const height = "";
        return height;
    }, []);

    const handleOnResize = useCallback(() => {
        if (isOpen) {
            setHeight(getNewHeight());
        }
    }, [setHeight, getNewHeight, isOpen]);

    const childrenWithProps = React.Children.map(props.children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { handleOnResize: handleOnResize });
        }
        return child;
    });

    useEffect(() => {
        setInitTriggered(true);
        window.addEventListener('load', handleOnResize);
        window.addEventListener('resize', handleOnResize);
    }, [handleOnResize])

    useEffect(() => {
        if (isOpen) {
            onOpen();
            setHeight(getNewHeight());
        } else {
            onClose();
            setHeight(0);
        }
    }, [isOpen, onOpen, onClose, getNewHeight])


    return (
        <div className={isOpen ? 'outer-accordian open' : 'outer-accordian'} style={initTriggered ? { height: (height + 'px') } : {}}>
            <div className='inner-accordian' ref={innerAccordian}>
                {
                    childrenWithProps
                }
            </div>
        </div>
    )
}

export default Accordian;