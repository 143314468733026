import React from 'react';

const ModalBody = () => {
    const personalAccountImg = 'https://s0.hfdstatic.com/higux/dist/images/routing-example-2.svg';
    const businessAccountImg = 'https://s0.hfdstatic.com/higux/dist/images/routing-example-1.svg';
    return (
        <div className="modal-body">
            <div className='modal-account-types-container'>
                <div className='modal-account-type'>
                    <h2 className='modal-account-type__title'>Personal Bank Account:</h2>
                    <div className='modal-account-type__img'>
                        <img src={personalAccountImg} alt="" />
                        <div class="sr-only"> The routing number on your check is found in the bottom left hand corner and will be in the format 12345678 space 9. Your account number will be to the right and be made up of 10 numbers.</div>
                    </div>
                </div>
                <div className='modal-account-type'>
                    <h2 className='modal-account-type__title'>Business Bank Account:</h2>
                    <div className='modal-account-type__img'>
                        <img src={businessAccountImg} alt="" />
                        <div class="sr-only"> The check number on your check is found in the bottom left hand corner. The routing number will be to the right of the check number and will be in the format 12345678 space 9. Your account number will be to the right and be made up of 10 numbers.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalBody;