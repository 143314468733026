import React, { useContext } from 'react';

import { ChoosePaymentContext } from '../../Contexts/ChoosePaymentModuleContext';
import BankAccountOwnerFormGroup from './form-groups/BankAccountOwnerFormGroup';
import AccountTypeFormGroup from './form-groups/AccountTypeFormGroup';
import AccountInformationFormGroup from './form-groups/AccountInformationFormGroup';
import FindRoutingAndAccountInfoModal from '../../FindRoutingAndAccountInfoModal';

const BankInfoForm = (props) => {
    const { state, setState } = useContext(ChoosePaymentContext);
    const personalAccountImg = 'https://s0.hfdstatic.com/higux/dist/images/routing-example-2.svg';

    const toggleHelpfulTooltip = () => {
        if (!state.tooltipAnimating) {
            setState((prevState) => {
                return { showHelpfulTooltip: !prevState.showHelpfulTooltip, tooltipAnimating: true }
            })
            setTimeout(() => {
                setState({ tooltipAnimating: false })
            }, 1000)
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="bank-info-form" onSubmit={handleOnSubmit}>
            <BankAccountOwnerFormGroup />
            <AccountTypeFormGroup />
            <div className="choose-bank-container">
                <AccountInformationFormGroup />
                <div className="account-info-image">
                    <img src={personalAccountImg} alt="" />
                    <div class="sr-only"> The routing number on your check is found in the bottom left hand corner and will be in the format 12345678 space 9. Your account number will be to the right and be made up of 10 numbers.</div>
                </div>
            </div>
            <FindRoutingAndAccountInfoModal isOpen={state.showHelpfulTooltip} toggleOpen={toggleHelpfulTooltip} />
        </form>
    );
}

export default BankInfoForm;