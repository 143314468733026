import React, { useContext } from 'react';

import { InitialLoadContext } from '../Contexts/InitialLoadContext';
let date = new Date();
const GlassBox = () => {
    const { state } = useContext(InitialLoadContext);
    let datetime = date.toLocaleString().slice(0,10)+" "+date.toString().split(' ')[4]
    datetime = +datetime.replace(/[^0-9.]/g,"");
    return (
        <div>
        <input type="hidden" name="tokenid" value={state.tokenId.slice(-4)+datetime} className="gbx-unmasked"/>
        <input type="hidden" name="partnerName" value={state.partnerName} className="gbx-unmasked"/>
        </div>
    )
}

export default GlassBox;