import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

import { ChoosePaymentContext } from '../Contexts/ChoosePaymentModuleContext';
import ModalHeader from './ModalHeader';
import ModalBackground from './ModalBackground';
import ModalBody from './ModalBody';

const FindRoutingAndAccountInfoModal = ({ isOpen, toggleOpen }) => {
    const { state } = useContext(ChoosePaymentContext);
    const [fadeIn, setFadeIn] = useState(state.showHelpfulTooltip);
    const [initialTrigger, setInitialTrigger] = useState(false);
    const [showModalBackground, setShowModalBackground] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        modalRef.current.style.display = 'none';
        setInitialTrigger(true);
    }, []);

    const openAnimation = useCallback(() => {
        setShowModalBackground(true);
        setTimeout(() => {
            modalRef.current.style.display = 'block';
        }, 0)

        setTimeout(() => {
            setFadeIn(true);
        }, 50)
    }, []);

    const closeAnimation = useCallback(() => {
        setFadeIn(false);
        setTimeout(() => {
            modalRef.current.style.display = 'none';
            setShowModalBackground(false);
        }, 80)
    }, []);

    const animateModal = useCallback(() => {
        if (isOpen) {
            openAnimation();
        } else {
            closeAnimation();
        }
    }, [isOpen, openAnimation, closeAnimation]);

    const onModalClick = (e) => {
        if (e.target === modalRef.current) {
            toggleOpen();
        }
    }

    useEffect(() => {
        if (initialTrigger) {
            animateModal();
        }
    }, [initialTrigger, animateModal]);

    return (
        <React.Fragment>
            <div ref={modalRef} className={fadeIn ? "modal fade in" : "modal fade"} id="accountNumbersModal" role="dialog" onClick={onModalClick}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content card offset">
                        <ModalHeader
                            text="Where can I find my check, routing and account numbers?"
                            toggleOpen={toggleOpen}
                        />
                        <ModalBody />
                        <div className="modal-footer">
                            <button className="btn btn-primary btn" data-dismiss="modal" onClick={toggleOpen}>Okay</button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBackground show={showModalBackground} fadeIn={fadeIn} />
        </React.Fragment>
    )
}

export default FindRoutingAndAccountInfoModal;