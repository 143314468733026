import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import FormGroup from '../../../FormGroup';

import CardMonthInput from './CardMonthInput';
import YearInput from './YearInput';
import { ChoosePaymentContext } from '../../../Contexts/ChoosePaymentModuleContext';

const expiration_data_dl = {"id":"Expiration date - Month","location":"HIG Payment Page","type":"drop down","da_track":"true"};
const expiration_error_data_dl = {"eventparent":"Page Tag","eventtype":"Field Level Message","eventvalue":"Name on card"};

const ExpirationDateFormGroup = (props) => {

    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [init, setInit] = useState(true);
    const { state, setState } = useContext(ChoosePaymentContext);

    const [error, setError] = useState('')

    useEffect(() => {
        if (!init) {
            validateExpiration()
        } setInit(false)
    }, [month, year])

    const validateExpiration = () => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        
        if((Number.parseInt(year)==currentYear && Number.parseInt(month) < Number.parseInt(currentMonth) + 1) ||(year == "year") || (month == "MM"))
        {
            let errors = getDateCardErrors();
            setState({expirationErrors:errors});
            var expiration_error_data = {
                "event_parent" : expiration_error_data_dl.eventparent, 
                "event_type" : expiration_error_data_dl.eventtype, 
                "event_id":  errors[0],
                "da_track": "true",
                "event_value": expiration_error_data_dl.eventvalue,
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            }
            window._trackAnalytics(expiration_error_data);
        } else {
            setState({expirationErrors:[]}); 
        }
    }

    const handleOnDateChange = (e) => {
        const value = e.target.value;
        setState({ expirationMonth: value });
        setMonth(value);
        if (e.type.toLowerCase()==='blur'){
            var date_data = {
                "event_parent" : "Page Tag",
                "event_type" : expiration_data_dl.type,
                "event_id":  expiration_data_dl.id,
                "da_track": expiration_data_dl.da_track,
                "event_value": window.location.hostname.concat(window.location.pathname),
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            };
            window._trackAnalytics(date_data);
        }
        validateExpiration();
    }

    const handleOnYearChange = (e) => {
        const value = e.target.value;

        setState({ expirationYear: value });
        setYear(value);
        if (e.type.toLowerCase()==='blur'){
            var year_data = {
                "event_parent" : "Page Tag",
                "event_type" : expiration_data_dl.type,
                "event_id":  "Expiration date - Year",
                "da_track": expiration_data_dl.da_track,
                "event_value": window.location.hostname.concat(window.location.pathname),
                "page_name" : "Payment Details Page",
                "external_partner_name": state.partnerName,
                "product_type":"Workers Compensation"
            };
            window._trackAnalytics(year_data);
        }
        validateExpiration();
    }
    const getDateCardErrors = (name) => {
        return ['Please enter a valid expiration date'];
    }

    const nameonaccount_error_data_dl = { "eventparent": "Page Tag", "eventtype": "Field Level Message", "eventvalue": "Name on bank account" };

    return (

        <FormGroup errors={state.expirationErrors}
            tealium-impression data-dl={JSON.stringify(expiration_error_data_dl)}>

            <div className="expiration-date-container" >
                <label id="expiration-date-label" className="control-label">Expiration date</label>
                <div className="form-group month-year-inputs" aria-describedby="expiration-date-label">
                    <CardMonthInput handleOnDateChange={handleOnDateChange} month={state.expirationMonth} error={state.expirationErrors} 
                    	tealium-change data-dl={JSON.stringify(expiration_data_dl)}/>
                    <YearInput handleOnYearChange={handleOnYearChange} year={state.expirationYear} 
                    	tealium-change data-dl={JSON.stringify(expiration_data_dl)}/>
                </div>
            </div>
        </FormGroup>
    )
}

export default ExpirationDateFormGroup;