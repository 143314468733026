import React, { useContext, useEffect } from 'react';

import { ChoosePaymentContext } from '../Contexts/ChoosePaymentModuleContext';
import Accordian from '../Accordian';
import AuthenticationMessage from '../AuthenticationMessage';
import PaymentDetailsMessage from '../PaymentDetailsMessage';
import CardPaymentBlock from './CardPaymentBlock';
import BankPaymentBlock from './BankPaymentBlock';
import BankPaymentOnlyBlock from './BankPaymentOnlyBlock';
import FraudWarningMessage from '../FraudWarningMessage';
import { InitialLoadContext } from '../Contexts/InitialLoadContext';

const paymenttype_data_dl = { "id": "Use My Debit or Credit Card", "location": "HIG Payment Page", "type": "radio button", "da_track": "true" };
const urlParams = new URLSearchParams(window.location.search);
const getParamCCOpt = urlParams.get("creditCardOpt");


const PaymenCheckbox = ({ isChecked, text, paymentType, onChange }) => {
    const handleOnKeyDown = (e) => { //for accessibility
        if (e.key.toLowerCase() === 'enter') {
            onChange(paymentType);
        }
    }

    return (
        <div className="radio">
            <label className="radio-inline payment-type__label labelRadioClass" htmlFor={'payment-' + paymentType} tabIndex="0" onClick={onChange.bind(null, paymentType)} onKeyDown={handleOnKeyDown}>
                <input id={'payment-' + paymentType} type="radio" className="form-control" value={text} name="VerticalRadio" data-error="Please check one option" checked={isChecked} tabIndex="-1" onChange={onChange.bind(null, paymentType)} />
                <span className="payment-type__text">{text}</span>
            </label>
        </div>
    )
}

const ChoosePaymentModule = (props) => {
    const { state, setState } = useContext(ChoosePaymentContext);
    const { state: initialLoadState } = useContext(InitialLoadContext);
    const onPaymentTypeChange = (type) => {
        setState({ paymentType: type })
    }

    useEffect(() => {
        if (getParamCCOpt) {
            setState({ showBankOnly: true })
        }
        if (state.paymentType.toLowerCase() === 'eft') {
            let getCreditCardSession = localStorage.getItem('setCreditCardSession');
            if (!getCreditCardSession || getCreditCardSession.toLowerCase() === 'true') {
                localStorage.removeItem('setCreditCardSession');
            }
            setState({
                nameOnCard: '',
                nameOnCardErrors: [],
                cardType: 'unknown',
                cardNumber: '',
                cardNumberErrors: [],
                expirationMonth: '',
                expirationYear: '',
                expirationErrors: [],
            });

            var data = {
                "event_parent": "Page Tag",
                "event_type": paymenttype_data_dl.type,
                "event_id": 'Use My Bank Account',
                "event_value": "Use My Bank Account",
                "page_name": "Payment Details Page",
                "da_track": paymenttype_data_dl.da_track,
                "external_partner_name": initialLoadState.partnerName,
                "product_type": "Workers Compensation"
            };
            window._trackAnalytics(data);
        } else if (state.paymentType.toLowerCase() === '005') {

            setState({
                nameOnAccount: '',
                nameOnAccountErrors: [],
                accountType: '',
                accountTypeErrors: [],
                routingNumber: '',
                routingNumberErrors: [],
                accountNumber: '',
                accountNumberErrors: [],
                bankName: ''
            });
            var data = {
                "event_parent": "Page Tag",
                "event_type": paymenttype_data_dl.type,
                "event_id": 'Use My Debit or Credit Card',
                "event_value": "Use My Debit or Credit Card",
                "page_name": "Payment Details Page",
                "da_track": paymenttype_data_dl.da_track,
                "external_partner_name": initialLoadState.partnerName,
                "product_type": "Workers Compensation"
            };
            window._trackAnalytics(data);
        }
    }, [state.paymentType, getParamCCOpt]);

    return (
        <div className="choose-payment__container">
            <h2 className="section-title">{!state.showBankOnly ? "Choose Your Payment Type" : "Payment Details"}</h2>
            <PaymentDetailsMessage />
            <div className={!state.showBankOnly ? "section-wrapper" : "section-wrapper no-cardblock"}>
                {initialLoadState.eqsIssuanceInd
                    ? <i className="icon icon-secure eqs-section-icon" alt="Padlock icon"></i>
                    : <i className="icon icon-secure section-icon" alt="Padlock icon"></i>}
                {
                    !state.showBankOnly &&
                    <>
                        <div className="payment-type__container">
                            <PaymenCheckbox
                                isChecked={state.paymentType.toLowerCase() === 'eft'}
                                text="Use My Bank Account"
                                paymentType="EFT"
                                onChange={onPaymentTypeChange}
                                tealium-change data-dl={JSON.stringify(paymenttype_data_dl)}
                            />
                            <PaymenCheckbox
                                isChecked={state.paymentType.toLowerCase() === '005'}
                                text="Use My Debit or Credit Card"
                                paymentType="005"
                                onChange={onPaymentTypeChange}
                                tealium-change data-dl={JSON.stringify(paymenttype_data_dl)}
                            />
                        </div>
                        <div className="payment-info__container">
                            <hr className="payment-info_container_starting_line" />
                            <Accordian isOpen={state.paymentType.toLowerCase() === '005'}>
                                <CardPaymentBlock />
                            </Accordian>


                            <Accordian isOpen={state.paymentType.toLowerCase() === 'eft'}>
                                <BankPaymentBlock />
                            </Accordian>

                        </div>
                    </>
                }
                {
                    state.showBankOnly &&
                    <BankPaymentOnlyBlock />
                }

            </div>
            <AuthenticationMessage />
            {
                initialLoadState.stateCd === 'NY' &&
                <FraudWarningMessage />
            }
        </div>
    )
}

export default ChoosePaymentModule;