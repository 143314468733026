import React from 'react';
import {wcPremium_data_dl} from '../TodaysPaymentModule';
const LineItem = ({data} = {}) => {    
    const {title="unavailable", price="", subcontent="", isLoading=false, isTotal=false} = data;

    const subtextContent = (text) => { 
        let newText = text.split('\n');
        return newText.join('<br/>')
    }

    const ErrorMessageBlock = () => {
        return (
            <>
            {subcontent && data?.error &&
                <div className="line-item__subcontent">
                    <div className="todays-payment__error">
                        <i class="icon icon-attention-alt error-sign"></i>
                        <span dangerouslySetInnerHTML={{__html: subtextContent(subcontent)}}></span>
                    </div>  
                </div>
            }
            </>
        )
    }

    return(
        <li className="line-item">
            <div className="line-item__content bolder">
                <h3 className="line-item__title">{title}</h3>
                {!isLoading && !!price && !isTotal &&
                    <p className="line-item__price" >{price}</p>
                }
                {!isLoading && !!price && !!isTotal &&
                    <p className="line-item__price" tealium-impression data-dl={JSON.stringify(wcPremium_data_dl)} >{price}</p>
                }
        
                {isLoading && 
                    <i className="icon icon-spinner icon-pulse"></i>
                }
            </div>
            {
                subcontent && !data?.error &&
                <div className="line-item__subcontent">
                    <p className="subcontent-para" dangerouslySetInnerHTML={{__html: subtextContent(subcontent)}}></p>
                </div>
            }
            <ErrorMessageBlock />
        </li>
    )
}

export default LineItem