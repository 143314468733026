import { setWebAnalyticsForHIGGenericErrorPage } from './services'

export const showGenericErrorPage = (initialState) => {
  
  if(initialState.forceShowErrorPage){
    return true;
  } else if (initialState.tokenResponseReceived && !initialState.tokenValid) {
    return true;
  } else if(initialState.tokenResponseReceived && initialState.pageValidationResponseReceived && !initialState.userType && !initialState.bopUserType){
    return true;
  }else {
    return false;
  }
};

export function forceShowGenericErrorPage(code, wcCode, bopCode, setState){
  //add webanlytics here
  if(code !== ""){
    setWebAnalyticsForHIGGenericErrorPage(code);
  }else{
    setWebAnalyticsForHIGGenericErrorPage(wcCode+bopCode);
  }
  setState({
    genericErrorPageErrorMessage: code,
    genericErrorPageWCErrorMessage: wcCode,
    genericErrorPageBOPErrorMessage: bopCode,
    forceShowErrorPage: true
  })
}

export const showPaymenyUIPage = (initialState) => {
  if (initialState.tokenResponseReceived && initialState.pageValidationResponseReceived) {
    return !showGenericErrorPage(initialState);
  }
  return false;
};

export const showProcessingPage = (initialState) => {
  if (!showGenericErrorPage(initialState) && !showPaymenyUIPage(initialState)) {
    //if no error page is shown and payment UI also not shown, show processing page
    return true;
  } else {
    return false;
  }
};

export const showExternalPage = (url, errorCd) => {
  const adder = errorCd ? "?errorMessage=" + errorCd : "";
  window.location.href = decodeURIComponent(url + adder);
}