import React, { useContext } from 'react';
import { InitialLoadContext } from '../Contexts/InitialLoadContext';
import { getCAPrivacyPolicy } from '../../utils/services';
import privacyoptions from '../../privacyoptions.svg';

const Footer = () => {
    const currentDate = new Date().getFullYear();
    const { state } = useContext(InitialLoadContext);
    if (state.eqsIssuanceInd == 'true') {
        return <></>;
    }
    else {
        return (
            <footer className="footer footer-thin">
                <div className="container">
                    <div className="row content-row">
                        <div className="col-md-12">
                            <ul className="links list-inline">
                                <li><a href="https://www.thehartford.com/legal-notice" target="_blank" rel="noreferrer">Legal Notice<span className="sr-only">Opens in new tab</span></a></li>
                                <li><a href="https://www.thehartford.com/accessibility-statement" target="_blank" rel="noreferrer">Accessibility Statement<span className="sr-only">Opens in new tab</span></a></li>
                                <li><a href="https://www.thehartford.com/online-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy<span className="sr-only">Opens in new tab</span></a></li>
                            	<li><a href={getCAPrivacyPolicy()} target="_blank" rel="noreferrer">Your California Privacy Choices <img id="PrivacyOptions" alt="" src={privacyoptions}/><span className="sr-only">Opens in new tab</span></a></li>
                            </ul>
                            <p className="copyright">{"© " + currentDate + " The Hartford"}</p>
                        </div>
                    </div>
                </div>
            </footer>

        )
    }
}
export default Footer;