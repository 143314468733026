import React, { useContext } from 'react';

import ChoosePaymentContextWrapper from "../Contexts/ChoosePaymentModuleContext";
import ChoosePaymentModule from "../ChoosePaymentModule";
import TodaysPaymentModule from "../TodaysPaymentModule";
import ContinueButton from '../ContinueButton';
import CancelIssueButton from '../CancelIssueButton';
import CancelButton from '../CancelButton';
import { InitialLoadContext } from '../Contexts/InitialLoadContext';
import { showPaymenyUIPage } from '../../utils/showPages';
import InstallmentOptionsModule from '../InstallmentOptions';

const PaymentUIPage = (props) => {
    const { state } = useContext(InitialLoadContext);
    const display = showPaymenyUIPage(state);
        
    return (
        <>
            {
                display &&
                <>
                    <TodaysPaymentModule />
                    <InstallmentOptionsModule />
                    <ChoosePaymentContextWrapper showBankOnly={state.wcAutoPay.toLowerCase() === 'yes' || state.bopAutoPay.toLowerCase() === 'yes'}>
                        <ChoosePaymentModule />
                        <div className="final-actions-container">
                            <ContinueButton />
                            {state.paymentFrameInd === 'true' ? <CancelIssueButton /> :  <CancelButton />}                       
                        </div>
                    </ChoosePaymentContextWrapper>
                </>
            }
        </>
    );


}

export default PaymentUIPage;