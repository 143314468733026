import React from 'react';

import BankAccountOwnerFormGroup from '../BankPaymentBlock/form-groups/BankAccountOwnerFormGroup';
import AccountTypeFormGroup from '../BankPaymentBlock/form-groups/AccountTypeFormGroup';
import AccountInformationFormGroup from '../BankPaymentBlock/form-groups/AccountInformationFormGroup';

const BankPaymentOnlyBlock = () => {
    const personalAccountImg = 'https://s0.hfdstatic.com/higux/dist/images/routing-example-2.svg';

    const handleOnSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div className="bank-only-container">
            <form className="bank-info-form" onSubmit={handleOnSubmit}>
                <div className="payment-header">
                    <h3 className="payment-header__title"><i className="icon icon-bank" alt="Building with columns icon"></i>Bank Account Details</h3>
                </div>
                <BankAccountOwnerFormGroup />
                <AccountTypeFormGroup />
                <div className="bank-only-account-info-container">
                    <AccountInformationFormGroup />
                    <div className="account-info-image">
                        <img src={personalAccountImg} alt="" />
                        <div class="sr-only"> The routing number on your check is found in the bottom left hand corner and will be in the format 12345678 space 9. Your account number will be to the right and be made up of 10 numbers.</div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BankPaymentOnlyBlock;